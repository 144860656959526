import ServiceFile from "../../../../api/services/domain/serviceFile";
import { ServiceFileCategory } from "../../../../api/services/domain/serviceFileCategory";
import { formatDateToSpanishShortDate } from "../../../shared/application/dates/dateTools";
import requireElementOfTypeById from "../../../shared/application/dom/requireElementOfTypeById";
import downloadBlob from "../../../shared/application/files/downloadBlob";
import useElementLoader from "../../../shared/application/loaders/useElementLoader";
import ModalHandler from "../../../shared/application/modals/modalHandler";
import Toast from "../../../shared/application/toasts/toast";
import initializeTooltips from "../../../shared/application/tooltip/initializeTooltips";
import Uuid from "../../../shared/domain/uuid";
import checkPermissionsForServiceType from "../../serviceType/infrastructure/checkPermissionsForServiceType";
import downloadServiceFileById from "../application/downloadServiceFileById";
import searchIfUserIsExpertFromTheService from "../application/searchIfUserIsExpertFromTheService";
import searchServiceFileCategoriesByServiceTypeId from "../application/searchServiceFileCategoriesByServiceTypeId";
import searchServiceFileCollectionByServiceId from "../application/searchServiceFileCollectionByServiceId";
import updateServiceFile from "../application/updateServiceFile";
import { ServiceFileCreated, ServiceFileDeleted, ServiceFileUpdated } from "../domain/serviceFileCustomEvents";
import openDeleteServiceFileModal from "./openServideFileDeleteModal";
import openUploadServiceFileModal from "./openServideFileUploadModal";

interface InitializeServiceFileModalParams {
  serviceId: string;
  serviceTypeId: string;
  uploadFilesPermission: boolean;
  deleteFilesPermission: boolean;
}

interface PrintModalDataParams {
  serviceFileCategoriesCollection: ServiceFileCategory[];
  serviceFileCollection: ServiceFile[];
  modalBody: HTMLElement;
  uploadButtonId: string;
  serviceId: string;
  modalId: string;
  deleteFilesPermission: boolean;
  uploadFilesPermission: boolean;
  selectedFileCategoryId: string | null;
}

interface PrintFieldsInTabsParams {
  modalId: string;
  deleteFilesPermission: boolean;
  uploadFilesPermission: boolean;
  serviceFileCollection: ServiceFile[];
  serviceFileCategoriesCollection: ServiceFileCategory[];
}

interface PrintServiceFileRowParams {
  serviceFile: ServiceFile;
  targetTabId: string;
  modalId: string;
  serviceFileCategoriesCollection: ServiceFileCategory[];
  deleteFilesPermission: boolean;
  uploadFilesPermission: boolean;
}

const renderBadge = (count: number): string => `<span class="badge ${count === 0 ? "badge-light" : "badge-secondary"}">${count}</span>`;

function printServiceFileRow({ serviceFile, targetTabId, modalId, serviceFileCategoriesCollection, deleteFilesPermission, uploadFilesPermission }: PrintServiceFileRowParams): HTMLElement {
  const { friendlyName, userAvatar, userName, createdAt, serviceFileId, serviceFileCategoryId, serviceFileCategoryName, serviceId } = serviceFile;

  const row = document.createElement("div");
  row.classList.add("col-12");
  row.setAttribute("data-target-tab", targetTabId);

  const inputGroup = document.createElement("div");
  inputGroup.classList.add("input-group", "mb-2");
  row.appendChild(inputGroup);

  const prependDiv = document.createElement("div");
  prependDiv.classList.add("input-group-prepend");
  inputGroup.appendChild(prependDiv);

  const spanFileIcon = document.createElement("span");
  spanFileIcon.id = `service-file-icon-container-${serviceFileId}`;
  spanFileIcon.classList.add("input-group-text");
  spanFileIcon.style.justifyContent = "center";
  prependDiv.appendChild(spanFileIcon);

  const fileIcon = document.createElement("i");
  fileIcon.classList.add("fas", "fa-file-alt", "text-muted");
  spanFileIcon.appendChild(fileIcon);

  const inputName = document.createElement("input");
  inputName.type = "text";
  inputName.classList.add("form-control", "font-weight-bold");
  inputName.readOnly = true;
  inputName.value = friendlyName;
  inputName.setAttribute("value", friendlyName);
  inputName.setAttribute("data-toggle", "tooltip");
  inputName.setAttribute("data-trigger", "hover");
  inputName.setAttribute("data-placement", "top");
  inputName.setAttribute("title", "Nombre del documento");
  inputGroup.appendChild(inputName);

  const inputDate = document.createElement("input");
  inputDate.type = "text";
  inputDate.classList.add("form-control", "text-center");
  inputDate.readOnly = true;
  const formattedDate = formatDateToSpanishShortDate(new Date(createdAt));
  inputDate.value = formattedDate;
  inputDate.setAttribute("value", formattedDate);
  inputDate.setAttribute("data-toggle", "tooltip");
  inputName.setAttribute("data-trigger", "hover");
  inputDate.setAttribute("data-placement", "top");
  inputDate.setAttribute("title", "Fecha de creación");
  inputDate.style.maxWidth = "125px";
  inputGroup.appendChild(inputDate);

  const categoryContainer = document.createElement("div");
  categoryContainer.classList.add("input-group-append");
  inputGroup.appendChild(categoryContainer);

  if (serviceFileCategoriesCollection && serviceFileCategoriesCollection.length && uploadFilesPermission) {
    serviceFileCategoriesCollection.sort((a, b) => a.name.localeCompare(b.name));
    serviceFileCategoriesCollection.sort((a, b) => (a.name === "Documentos" ? -1 : b.name === "Documentos" ? 1 : 0));

    const select = document.createElement("select");
    select.title = "Categoría";
    select.id = `service-file-category-select-${serviceFileId}`;
    select.classList.add("form-control", "input-sm");
    select.style.minWidth = "150px";

    serviceFileCategoriesCollection.forEach((fileCategory) => {
      const option = document.createElement("option");
      option.value = fileCategory.serviceFileCategoryId;
      option.textContent = fileCategory.name;
      if (fileCategory.serviceFileCategoryId === serviceFileCategoryId) {
        option.selected = true;
      }
      select.appendChild(option);
    });

    categoryContainer.appendChild(select);

    select.addEventListener("change", async function (this: HTMLSelectElement) {
      const response = await updateServiceFile({ serviceId, serviceFileId }, { serviceFileCategoryId: select.value });
      if (response !== null) {
        Toast.show({ title: "Actualizado", subtitle: "Categoría actualizada", content: "La categoría del documento ha sido actualizada.", type: "success", delay: 3000 });

        requireElementOfTypeById(modalId, HTMLElement).dispatchEvent(new CustomEvent(ServiceFileUpdated));
      } else {
        Toast.show({ title: "Error", subtitle: "Error al actualizar", content: "No se pudo actualizar la categoría del documento.", type: "error", delay: 5000 });
      }
    });
  } else {
    const inputCategory = document.createElement("input");
    inputCategory.type = "text";
    inputCategory.classList.add("form-control");
    inputCategory.readOnly = true;
    inputCategory.value = serviceFileCategoryName;
    inputCategory.setAttribute("value", serviceFileCategoryName);
    inputCategory.setAttribute("data-toggle", "tooltip");
    inputCategory.setAttribute("data-trigger", "hover");
    inputCategory.setAttribute("data-placement", "top");
    inputCategory.setAttribute("title", "Categoría");

    categoryContainer.appendChild(inputCategory);
  }

  const img = document.createElement("img");
  img.src = userAvatar;
  img.alt = userName;
  img.classList.add("rounded-circle", "mx-2");
  img.width = 30;
  img.height = 30;
  img.setAttribute("data-toggle", "tooltip");
  img.setAttribute("data-trigger", "hover");
  img.setAttribute("data-placement", "top");
  img.setAttribute("title", "Fichero subido por " + userName);
  inputGroup.appendChild(img);

  const buttonsContainer = document.createElement("div");
  buttonsContainer.classList.add("input-group-append", "ml-auto", "d-flex", "align-items-center");
  inputGroup.appendChild(buttonsContainer);

  const downloadButton = document.createElement("button");
  downloadButton.id = `download-service-file-${serviceFileId}`;
  downloadButton.classList.add("btn", "btn-barymont-red", "mr-2");
  downloadButton.setAttribute("data-toggle", "tooltip");
  downloadButton.setAttribute("data-trigger", "hover");
  downloadButton.setAttribute("data-placement", "top");
  downloadButton.setAttribute("title", "Descargar documento");

  downloadButton.addEventListener("click", async function (this: HTMLButtonElement) {
    const downloadButtonLoader = useElementLoader({ elementId: this.id, loadingText: "", minLoadSeconds: 1 });
    downloadButtonLoader.start();

    const response = await downloadServiceFileById({
      serviceId,
      serviceFileId,
    });

    if (response === null) {
      Toast.show({ title: "Error", subtitle: "Error al descargar el documento", content: "No se pudo descargar el documento, ponte en contacto con soporte.", type: "error", delay: 5000 });
      downloadButtonLoader.stop();
      return;
    }

    downloadBlob({ blob: response, friendlyName });
    Toast.show({ title: "Descarga", subtitle: "Descarga completa", content: `El documento ${friendlyName} ha sido descargado.`, type: "success", delay: 3000 });

    downloadButtonLoader.stop();
  });

  buttonsContainer.appendChild(downloadButton);

  const downloadIcon = document.createElement("i");
  downloadIcon.classList.add("fas", "fa-download");
  downloadButton.appendChild(downloadIcon);

  if (deleteFilesPermission) {
    const deleteButton = document.createElement("button");
    deleteButton.classList.add("btn", "btn-barymont-black");
    deleteButton.setAttribute("data-delete-file-action", "true");
    deleteButton.setAttribute("role", "button");
    deleteButton.setAttribute("data-toggle", "tooltip");
    deleteButton.setAttribute("data-placement", "top");
    deleteButton.setAttribute("title", "Eliminar documento");
    deleteButton.setAttribute("id", `delete-service-file-${serviceFileId}`);

    deleteButton.addEventListener("click", () => {
      openDeleteServiceFileModal({
        serviceId,
        serviceFileId,
        parentModalId: modalId,
        buttonId: deleteButton.id,
      });
    });

    buttonsContainer.appendChild(deleteButton);

    const deleteIcon = document.createElement("i");
    deleteIcon.classList.add("far", "fa-trash-alt");
    deleteButton.appendChild(deleteIcon);
  }

  return row;
}

function buildModalHTML({ modalId, modalBodyId }: { modalId: string; modalBodyId: string }): string {
  return `
    <div id="${modalId}" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title">Documentos</h1>
          </div>
          <div class="modal-body" id="${modalBodyId}"></div>
          <div class="modal-footer">
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>`;
}

function buildCategoryTabs(serviceFileCategoriesCollection: ServiceFileCategory[], serviceFileCollection: ServiceFile[], selectedFileCategoryId: string | null): string {
  serviceFileCategoriesCollection.sort((a, b) => a.name.localeCompare(b.name));
  serviceFileCategoriesCollection.sort((a, b) => (a.name === "Documentos" ? -1 : b.name === "Documentos" ? 1 : 0));

  const totalCount = serviceFileCollection.length;
  const todosTab = `<li class="nav-item">
                      <a class="nav-link ${selectedFileCategoryId === null ? "active" : ""}" data-service-file-category-id id="tab-all" data-toggle="tab" href="#tab-content-all" role="tab" aria-controls="tab-content-all" aria-selected="true">
                        Todos ${renderBadge(totalCount)}
                      </a>
                    </li>`;

  const categoryTabs =
    serviceFileCategoriesCollection && serviceFileCategoriesCollection.length
      ? serviceFileCategoriesCollection
          .map((category) => {
            const count = serviceFileCollection.filter((file) => file.serviceFileCategoryId === category.serviceFileCategoryId).length;
            return `<li class="nav-item">
                      <a class="nav-link ${selectedFileCategoryId === category.serviceFileCategoryId ? "active" : ""}" data-service-file-category-id="${category.serviceFileCategoryId}" id="tab-${category.serviceFileCategoryId}" data-toggle="tab" href="#tab-content-${category.serviceFileCategoryId}" role="tab" aria-controls="tab-content-${category.serviceFileCategoryId}" aria-selected="false">
                        ${category.name} ${renderBadge(count)}
                      </a>
                    </li>`;
          })
          .join("")
      : "";

  const navTabs = todosTab + categoryTabs;
  const todosPane = `<div class="tab-pane fade ${selectedFileCategoryId === null ? " show active" : ""} py-2 scroll-barymont-red" id="tab-content-all" role="tabpanel" aria-labelledby="tab-all" style="min-height:269px; max-height: 60vh; overflow-y: auto;"></div>`;
  const categoryPanes = serviceFileCategoriesCollection && serviceFileCategoriesCollection.length ? serviceFileCategoriesCollection.map((category) => `<div class="tab-pane fade ${selectedFileCategoryId === category.serviceFileCategoryId ? "show active" : ""} py-2 scroll-barymont-red" id="tab-content-${category.serviceFileCategoryId}" role="tabpanel" aria-labelledby="tab-${category.serviceFileCategoryId}" style="min-height:269px; max-height: 60vh; overflow-y: auto;"></div>`).join("") : "";

  return `
    <ul class="nav nav-tabs" role="tablist">
      ${navTabs}
    </ul>
    <div class="tab-content border border-top-0 rounded-bottom">
      ${todosPane}
      ${categoryPanes}
    </div>`;
}

function printFilesInTabs({ modalId, deleteFilesPermission, uploadFilesPermission, serviceFileCollection, serviceFileCategoriesCollection }: PrintFieldsInTabsParams): void {
  const allTabContainerId = "tab-content-all";
  const allContainer = document.getElementById(allTabContainerId);
  if (allContainer) {
    if (serviceFileCollection && serviceFileCollection.length) {
      const sortedFiles = serviceFileCollection.slice().sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      sortedFiles.forEach((file) => {
        const rowEl = printServiceFileRow({ serviceFile: file, targetTabId: allTabContainerId, modalId, serviceFileCategoriesCollection, deleteFilesPermission, uploadFilesPermission });
        allContainer.appendChild(rowEl);
      });
    } else {
      allContainer.insertAdjacentHTML("beforeend", `<div class="alert alert-light text-center">No hay documentos en este servicio</div>`);
    }
  }

  if (serviceFileCategoriesCollection && serviceFileCollection) {
    serviceFileCategoriesCollection.forEach((category) => {
      const targetContainerId = `tab-content-${category.serviceFileCategoryId}`;
      const container = document.getElementById(targetContainerId);
      if (container) {
        const filesForCategory = serviceFileCollection.filter((file) => file.serviceFileCategoryId === category.serviceFileCategoryId);
        if (filesForCategory.length) {
          filesForCategory.forEach((file) => {
            const rowEl = printServiceFileRow({ serviceFile: file, targetTabId: targetContainerId, modalId, serviceFileCategoriesCollection, deleteFilesPermission, uploadFilesPermission });
            container.appendChild(rowEl);
          });
        } else {
          container.insertAdjacentHTML("beforeend", `<div class="alert alert-light my-0 text-center">No hay documentos en esta categoría</div>`);
        }
      }
    });
  }
}

function createUploadButton(uploadButtonId: string, serviceId: string, modalId: string, serviceFileCategoriesCollection?: ServiceFileCategory[]): HTMLElement {
  const uploadFileButton = document.createElement("button");
  uploadFileButton.id = uploadButtonId;
  uploadFileButton.classList.add("btn", "btn-barymont-black", "mt-3", "w-100");
  uploadFileButton.innerHTML = '<i class="fas fa-solid fa-upload pr-2"></i> Subir nuevo documento';

  uploadFileButton.addEventListener("click", () => {
    openUploadServiceFileModal({ serviceId, parentModalId: modalId, buttonId: uploadButtonId, serviceFileCategoriesCollection });
  });

  return uploadFileButton;
}

function printModalData({ serviceFileCategoriesCollection, serviceFileCollection, modalBody, uploadButtonId, serviceId, modalId, deleteFilesPermission, uploadFilesPermission, selectedFileCategoryId }: PrintModalDataParams): void {
  const tabsHTML = buildCategoryTabs(serviceFileCategoriesCollection, serviceFileCollection, selectedFileCategoryId);
  modalBody.insertAdjacentHTML("beforeend", tabsHTML);
  printFilesInTabs({ modalId, serviceFileCollection, serviceFileCategoriesCollection, deleteFilesPermission, uploadFilesPermission });

  if (uploadFilesPermission) {
    const uploadFileButton = createUploadButton(uploadButtonId, serviceId, modalId, serviceFileCategoriesCollection);
    modalBody.appendChild(uploadFileButton);
  }

  initializeTooltips();
}

function initializeServiceFileModal({ serviceId, serviceTypeId, uploadFilesPermission, deleteFilesPermission }: InitializeServiceFileModalParams): void {
  const randomUuid = Uuid.random().value;
  const modalId = `create-service-request-modal-${randomUuid}`;
  const modalBodyId = `modal-body-${randomUuid}`;
  const uploadButtonId = `upload-service-file-button-${randomUuid}`;

  const modalHTML = buildModalHTML({ modalId, modalBodyId });
  document.body.insertAdjacentHTML("beforeend", modalHTML);

  $(" #" + modalId).on("shown.bs.tab", function (event: Event) {
    if (event.target instanceof HTMLElement) {
      document.cookie = `${serviceId}_selectedFileCategoryId=${event.target.dataset.serviceFileCategoryId || ""}; path=/; max-age=600;`;
    }
  });

  const reloadModalData = async (): Promise<void> => {
    try {
      const serviceFileCollection: ServiceFile[] = await searchServiceFileCollectionByServiceId(serviceId);
      const serviceFileCategoriesCollection: ServiceFileCategory[] = await searchServiceFileCategoriesByServiceTypeId(serviceTypeId);

      const modalBody = document.getElementById(modalBodyId);

      const selectedFileCategoryCookieValue = document.cookie
        .split("; ")
        .find((row) => row.startsWith(`${serviceId}_selectedFileCategoryId=`))
        ?.split("=")[1];

      const selectedFileCategoryId = selectedFileCategoryCookieValue !== "" && selectedFileCategoryCookieValue !== undefined ? selectedFileCategoryCookieValue : null;

      if (modalBody) {
        modalBody.innerHTML = "";
        printModalData({ serviceFileCategoriesCollection, serviceFileCollection, modalBody, uploadButtonId, serviceId, modalId, deleteFilesPermission, uploadFilesPermission, selectedFileCategoryId });
      }
    } catch {
      Toast.show({ title: "Error", subtitle: "Error al recargar los documentos", content: "No se pudieron cargar los documentos", type: "error", delay: 5000 });
    }
  };

  const modalElement = requireElementOfTypeById(modalId, HTMLElement);

  [ServiceFileCreated, ServiceFileUpdated, ServiceFileDeleted].forEach((eventName) => {
    modalElement.addEventListener(eventName, () => {
      void reloadModalData();
    });
  });

  void reloadModalData().then(() => {
    ModalHandler.init(modalId).show().onHideRemove();
  });
}

export default async function openServiceFileModal(serviceId: string, serviceTypeId: string): Promise<void> {
  const uploadFilesPermission = await checkPermissionsForServiceType({
    serviceType: serviceTypeId,
    accessCheckers: {
      financialEducationServiceAccessChecker: "FinancialEducationService:FinancialEducationServiceDocumentsManageAccessChecker",
      spadServiceAccessChecker: "SpadService:SpadServiceDocumentsManageAccessChecker",
      pymeServiceAccessChecker: "PymeService:PymeServiceDocumentsManageAccessChecker",
      cpServiceAccessChecker: "CpService:CpServiceDocumentsManageAccessChecker",
      financialPlanningServiceAccessChecker: "FinancialPlanningService:FinancialPlanningServiceManageAccessChecker",
    },
    checkFunctions: {
      pymeServiceCheckFunction: async () => await searchIfUserIsExpertFromTheService(serviceId),
    },
  });

  const deleteFilesPermission = await checkPermissionsForServiceType({
    serviceType: serviceTypeId,
    accessCheckers: {
      financialEducationServiceAccessChecker: "FinancialEducationService:FinancialEducationServiceDocumentsManageAccessChecker",
      spadServiceAccessChecker: "SpadService:SpadServiceDocumentsManageAccessChecker",
      pymeServiceAccessChecker: "PymeService:PymeServiceDocumentsManageAccessChecker",
      cpServiceAccessChecker: "CpService:CpServiceDocumentsManageAccessChecker",
      financialPlanningServiceAccessChecker: "FinancialPlanningService:FinancialPlanningServiceManageAccessChecker",
    },
  });

  try {
    initializeServiceFileModal({
      serviceId,
      serviceTypeId,
      uploadFilesPermission,
      deleteFilesPermission,
    });
  } catch {
    Toast.show({ title: "Error", subtitle: "Error al abrir los documentos", content: "No se pudieron cargar los documentos, ponte en contacto con soporte.", type: "error", delay: 5000 });
  }
}
