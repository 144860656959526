import useElementLoader from "../../../shared/application/loaders/useElementLoader";
import ModalHandler from "../../../shared/application/modals/modalHandler";
import Toast from "../../../shared/application/toasts/toast";
import Uuid from "../../../shared/domain/uuid";
import removeServiceFileById from "../application/removeServiceFileById";
import { ServiceFileDeleted } from "../domain/serviceFileCustomEvents";

function createModalElement(modalId: string, confirmButtonId: string): HTMLElement {
  const modalDiv = document.createElement("div");
  modalDiv.id = modalId;
  modalDiv.classList.add("modal", "fade");
  modalDiv.setAttribute("tabindex", "-1");
  modalDiv.setAttribute("role", "dialog");

  const modalDialog = document.createElement("div");
  modalDialog.classList.add("modal-dialog", "modal-dialog-centered", "modal-md");
  modalDialog.setAttribute("role", "document");
  modalDiv.appendChild(modalDialog);

  const modalContent = document.createElement("div");
  modalContent.classList.add("modal-content");
  modalDialog.appendChild(modalContent);

  const modalHeader = document.createElement("div");
  modalHeader.classList.add("modal-header");
  modalContent.appendChild(modalHeader);

  const modalTitle = document.createElement("h5");
  modalTitle.classList.add("modal-title");
  modalTitle.id = "modal-delete-service-file-label";
  modalTitle.textContent = "Eliminar fichero";
  modalHeader.appendChild(modalTitle);

  const closeButton = document.createElement("button");
  closeButton.type = "button";
  closeButton.classList.add("close");
  closeButton.setAttribute("data-dismiss", "modal");
  closeButton.setAttribute("aria-label", "Cerrar");

  const span = document.createElement("span");
  span.setAttribute("aria-hidden", "true");
  span.textContent = "×";
  closeButton.appendChild(span);
  modalHeader.appendChild(closeButton);

  const modalBody = document.createElement("div");
  modalBody.classList.add("modal-body");
  modalContent.appendChild(modalBody);

  const modalFooter = document.createElement("div");
  modalFooter.classList.add("modal-footer");
  modalContent.appendChild(modalFooter);

  const footerConfirmButton = document.createElement("button");
  footerConfirmButton.type = "button";
  footerConfirmButton.classList.add("btn", "btn-barymont-red");
  footerConfirmButton.textContent = "Eliminar";
  footerConfirmButton.id = confirmButtonId;
  modalFooter.appendChild(footerConfirmButton);

  const footerCloseButton = document.createElement("button");
  footerCloseButton.type = "button";
  footerCloseButton.classList.add("btn", "btn-barymont-black");
  footerCloseButton.setAttribute("data-dismiss", "modal");
  footerCloseButton.textContent = "Cancelar";
  modalFooter.appendChild(footerCloseButton);

  return modalDiv;
}

function createModalBodyElements(): HTMLElement {
  const alertDiv = document.createElement("div");
  alertDiv.classList.add("alert", "alert-danger", "mb-0", "text-center");
  alertDiv.setAttribute("role", "alert");
  alertDiv.textContent = "¿Estás seguro de que deseas eliminar el fichero?";
  return alertDiv;
}

export default function openDeleteServiceFileModal({ serviceId, serviceFileId, parentModalId, buttonId }: { serviceId: string; serviceFileId: string; parentModalId: string; buttonId: string }): void {
  const parentButtonIdWhoOpenTheModal = useElementLoader({ elementId: buttonId, loadingText: "", minLoadSeconds: 1, autoStopSeconds: 1 });
  parentButtonIdWhoOpenTheModal.start();

  const uuid = Uuid.random().value;
  const modalId = `delete-service-file-modal-${uuid}`;
  const confirmButtonId = `confirm-delete-service-file-${uuid}`;

  const modalElement = createModalElement(modalId, confirmButtonId);

  const modalBody = modalElement.querySelector(".modal-body");
  if (!modalBody) return;
  modalBody.appendChild(createModalBodyElements());

  document.body.appendChild(modalElement);
  const deleteModal = ModalHandler.init(modalId).onHideRemove().show();

  const confirmDeleteButtonLoader = useElementLoader({ elementId: confirmButtonId, loadingText: "Eliminando...", minLoadSeconds: 1 });
  const footerConfirmButton = modalElement.querySelector(`#${confirmButtonId}`);

  if (footerConfirmButton) {
    footerConfirmButton.addEventListener("click", async () => {
      confirmDeleteButtonLoader.start();

      const response = await removeServiceFileById({ serviceId, serviceFileId });

      if (response === null) {
        Toast.show({ title: "Error", subtitle: "Error al eliminar el documento", content: "No se ha podido eliminar el documento. Inténtalo de nuevo o ponte en contacto con soporte.", type: "error", delay: 5000 });
        confirmDeleteButtonLoader.stop();
        return;
      }

      Toast.show({ title: "Documento eliminado", subtitle: "Eliminación correcta", content: "El documento se ha eliminado correctamente.", type: "success", delay: 5000 });

      const parentModal = document.getElementById(parentModalId);

      if (parentModal === null) {
        Toast.show({ title: "Error", subtitle: "Error al eliminar el documento", content: "No se ha podido eliminar el documento. Inténtalo de nuevo o ponte en contacto con soporte.", type: "error", delay: 5000 });
        return;
      }

      parentModal.dispatchEvent(new CustomEvent(ServiceFileDeleted));
      document.dispatchEvent(new CustomEvent(ServiceFileDeleted));

      parentButtonIdWhoOpenTheModal.stop();
      confirmDeleteButtonLoader.stop();

      deleteModal.hide();
    });
  }

  const modal = document.getElementById(modalId);
  if (modal) {
    modal.addEventListener("hidden.bs.modal", () => {
      parentButtonIdWhoOpenTheModal.stop();
    });
  }

  deleteModal.show();
}
