import { ServiceFileCategory } from "../../../../api/services/domain/serviceFileCategory";
import useElementLoader from "../../../shared/application/loaders/useElementLoader";
import ModalHandler from "../../../shared/application/modals/modalHandler";
import Toast from "../../../shared/application/toasts/toast";
import Uuid from "../../../shared/domain/uuid";
import uploadServiceFile from "../application/uploadServiceFile";
import { ServiceFileCreated } from "../domain/serviceFileCustomEvents";

function createModalElement(modalId: string): HTMLElement {
  const modalDiv = document.createElement("div");
  modalDiv.id = modalId;
  modalDiv.classList.add("modal", "fade");
  modalDiv.setAttribute("tabindex", "-1");
  modalDiv.setAttribute("role", "dialog");

  const modalDialog = document.createElement("div");
  modalDialog.classList.add("modal-dialog", "modal-dialog-centered", "modal-md");
  modalDialog.setAttribute("role", "document");
  modalDiv.appendChild(modalDialog);

  const modalContent = document.createElement("div");
  modalContent.classList.add("modal-content");
  modalDialog.appendChild(modalContent);

  const modalHeader = document.createElement("div");
  modalHeader.classList.add("modal-header");
  modalContent.appendChild(modalHeader);

  const modalTitle = document.createElement("h5");
  modalTitle.classList.add("modal-title");
  modalTitle.id = "modal-upload-service-file-label";
  modalTitle.textContent = "Subir documento";
  modalHeader.appendChild(modalTitle);

  const closeButton = document.createElement("button");
  closeButton.type = "button";
  closeButton.classList.add("close");
  closeButton.setAttribute("data-dismiss", "modal");
  closeButton.setAttribute("aria-label", "Cerrar");

  const span = document.createElement("span");
  span.setAttribute("aria-hidden", "true");
  span.textContent = "×";

  closeButton.appendChild(span);
  modalHeader.appendChild(closeButton);

  const modalBody = document.createElement("div");
  modalBody.classList.add("modal-body");
  modalContent.appendChild(modalBody);

  const modalFooter = document.createElement("div");
  modalFooter.classList.add("modal-footer");
  modalContent.appendChild(modalFooter);

  const footerCloseButton = document.createElement("button");
  footerCloseButton.type = "button";
  footerCloseButton.classList.add("btn", "btn-barymont-black");
  footerCloseButton.setAttribute("data-dismiss", "modal");
  footerCloseButton.textContent = "Cerrar";
  modalFooter.appendChild(footerCloseButton);

  return modalDiv;
}

function createModalBodyElements(formId: string, fileInputId: string, documentNameInputId: string, categorySelectId: string, submitButtonId: string, selectedFileCategoryId: string | null, serviceFileCategoriesCollection?: ServiceFileCategory[]): HTMLElement {
  const form = document.createElement("form");
  form.id = formId;

  const customFileDiv = document.createElement("div");
  customFileDiv.classList.add("custom-file");
  form.appendChild(customFileDiv);

  const fileInput = document.createElement("input");
  fileInput.id = fileInputId;
  fileInput.classList.add("custom-file-input");
  fileInput.type = "file";
  fileInput.required = true;
  customFileDiv.appendChild(fileInput);

  const fileLabel = document.createElement("label");
  fileLabel.setAttribute("for", fileInputId);
  fileLabel.classList.add("custom-file-label");
  fileLabel.setAttribute("data-browse", "Selecciona el fichero");
  fileLabel.textContent = "Selecciona el fichero";
  customFileDiv.appendChild(fileLabel);

  const nameGroupDiv = document.createElement("div");
  nameGroupDiv.classList.add("form-group", "mt-2");
  form.appendChild(nameGroupDiv);

  const nameLabel = document.createElement("label");
  nameLabel.innerHTML = 'Nombre del documento: <i class="fas fa-asterisk text-barymont-red"></i>';
  nameGroupDiv.appendChild(nameLabel);

  const nameInput = document.createElement("input");
  nameInput.type = "text";
  nameInput.id = documentNameInputId;
  nameInput.classList.add("form-control", "w-100");
  nameInput.minLength = 5;
  nameInput.maxLength = 250;
  nameInput.placeholder = "Introduce un nombre";
  nameInput.required = true;
  nameGroupDiv.appendChild(nameInput);

  const categoryGroupDiv = document.createElement("div");
  categoryGroupDiv.classList.add("form-group");
  form.appendChild(categoryGroupDiv);

  const categoryLabel = document.createElement("label");
  categoryLabel.innerHTML = 'Categoría del documento: <i class="fas fa-asterisk text-barymont-red"></i>';
  categoryGroupDiv.appendChild(categoryLabel);

  const categorySelect = document.createElement("select");
  categorySelect.id = categorySelectId;
  categorySelect.classList.add("form-control");
  categorySelect.required = true;
  if (serviceFileCategoriesCollection && serviceFileCategoriesCollection.length) {
    serviceFileCategoriesCollection.forEach((category) => {
      const option = document.createElement("option");
      option.value = category.serviceFileCategoryId;
      option.selected = selectedFileCategoryId === category.serviceFileCategoryId;
      option.textContent = category.name;
      categorySelect.appendChild(option);
    });
  } else {
    const option = document.createElement("option");
    option.value = "";
    option.textContent = "Sin categorías";
    categorySelect.appendChild(option);
  }
  categoryGroupDiv.appendChild(categorySelect);

  const submitButton = document.createElement("button");
  submitButton.type = "submit";
  submitButton.id = submitButtonId;
  submitButton.classList.add("btn", "btn-lg", "btn-barymont-red", "btn-block");
  submitButton.innerHTML = `<i class="fas fa-solid fa-upload mr-2"></i> Subir documento`;
  form.appendChild(submitButton);

  return form;
}

export default function openUploadServiceFileModal({ serviceId, parentModalId, buttonId, serviceFileCategoriesCollection }: { serviceId: string; parentModalId: string; buttonId: string; serviceFileCategoriesCollection?: ServiceFileCategory[] }): void {
  const parentButtonIdWhoOpenTheModal = useElementLoader({ elementId: buttonId, loadingText: "", minLoadSeconds: 1, autoStopSeconds: 1 });
  parentButtonIdWhoOpenTheModal.start();

  const uuid = Uuid.random().value;
  const modalId = `upload-service-file-modal-${uuid}`;
  const formId = `upload-service-file-form-${uuid}`;
  const fileInputId = `fileinput-${uuid}`;
  const documentNameInputId = `document-name-input-${uuid}`;
  const categorySelectId = `category-select-${uuid}`;
  const submitButtonId = `submit-upload-button-${uuid}`;

  const modalElement = createModalElement(modalId);

  const modalBody = modalElement.querySelector(".modal-body");

  if (!modalBody) return;

  const selectedFileCategoryCookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${serviceId}_selectedFileCategoryId=`))
    ?.split("=")[1];

  const selectedFileCategoryId = selectedFileCategoryCookieValue !== "" && selectedFileCategoryCookieValue !== undefined ? selectedFileCategoryCookieValue : null;

  const formElement = createModalBodyElements(formId, fileInputId, documentNameInputId, categorySelectId, submitButtonId, selectedFileCategoryId, serviceFileCategoriesCollection);

  modalBody.appendChild(formElement);

  document.body.appendChild(modalElement);

  const ModalHendler = ModalHandler.init(modalId).onHideRemove();

  const fileInputElement = modalElement.querySelector(`input#${fileInputId}`);
  const fileLabelElement = modalElement.querySelector(`label[for="${fileInputId}"]`);

  if (!(fileLabelElement instanceof HTMLLabelElement) || !(fileInputElement instanceof HTMLInputElement)) {
    ModalHandler.init(modalId).hide();

    Toast.show({ title: "Error", subtitle: "Error al cargar el formulario", content: "No se pudo cargar el formulario de subida de documento, ponte en contacto con soporte.", type: "error", delay: 5000 });
    return;
  }

  fileInputElement.addEventListener("change", () => {
    if (fileInputElement.files && fileInputElement.files.length > 0) {
      fileLabelElement.textContent = fileInputElement.files[0]!.name;
    } else {
      fileLabelElement.textContent = "Selecciona el fichero";
    }
  });

  formElement.addEventListener("submit", async (event) => {
    event.preventDefault();
    const loader = useElementLoader({ elementId: submitButtonId, loadingText: "Subiendo documento...", minLoadSeconds: 1 });
    loader.start();

    const fileInput = document.getElementById(fileInputId) as HTMLInputElement;
    const documentNameInput = document.getElementById(documentNameInputId) as HTMLInputElement;
    const categorySelect = document.getElementById(categorySelectId) as HTMLSelectElement;

    if (!fileInput.files || fileInput.files.length === 0) {
      loader.stop();

      Toast.show({ title: "Error", subtitle: "Fichero no seleccionado", content: "Selecciona un fichero para subir", type: "error", delay: 5000 });
      return;
    }

    const file: File = fileInput.files[0]!;
    const friendlyName = documentNameInput.value;
    const serviceFileCategoryId = categorySelect.value;

    const responseMessage = await uploadServiceFile({ serviceId }, { friendlyName, serviceFileCategoryId, file });

    Toast.show({
      title: responseMessage.correctlyUploaded ? "Documento subido" : "Error",
      subtitle: responseMessage.correctlyUploaded ? "Subida correcta" : "Error al subir el documento",
      content: responseMessage.correctlyUploaded ? "El documento se ha subido correctamente" : responseMessage.message,
      type: responseMessage.correctlyUploaded ? "success" : "error",
      delay: 5000,
    });

    if (responseMessage.correctlyUploaded === false) {
      loader.stop();
      return;
    }

    const parentModal = document.getElementById(parentModalId);

    if (!(parentModal instanceof HTMLElement)) {
      Toast.show({ title: "Error", subtitle: "Error al recargar los documentos", content: "No se ha podido recargar los documentos, recarga la página", type: "error", delay: 5000 });
      loader.stop();
      return;
    }
    parentModal.dispatchEvent(new CustomEvent(ServiceFileCreated));
    document.dispatchEvent(new CustomEvent(ServiceFileCreated));

    ModalHendler.hide();
    loader.stop();
  });

  ModalHandler.init(modalId).show();
}
