import handleApiResponse from "../../core/application/handleApiResponse";

interface UploadServiceFileParams {
  serviceId: string;
}

interface UploadServiceFilePayload {
  friendlyName: string;
  serviceFileCategoryId: string;
  file: File;
}

interface UploadServiceFileResponse {
  message: string;
}

export default async function uploadServiceFile(params: UploadServiceFileParams, payload: UploadServiceFilePayload): Promise<UploadServiceFileResponse> {
  const formData = new FormData();

  formData.append("friendlyName", payload.friendlyName);
  formData.append("serviceFileCategoryId", payload.serviceFileCategoryId);
  formData.append("file", payload.file);

  const response = await fetch(`/api/service/${params.serviceId}/file`, {
    method: "POST",
    body: formData,
  });

  return handleApiResponse<UploadServiceFileResponse>(response);
}
