import handleApiResponse from "../../core/application/handleApiResponse";

interface SearchServiceFileParams {
  serviceId: string;
  serviceFileId: string;
}

interface SearchServiceFileResponse {
  message?: string;
  file?: Blob;
}

export default async function searchServiceFile(params: SearchServiceFileParams): Promise<SearchServiceFileResponse> {
  const url = `/api/service/${params.serviceId}/file/${params.serviceFileId}`;
  const response = await fetch(url, { method: "GET" });

  const contentType = response.headers.get("Content-Type") || "";
  if (contentType.includes("application/json")) {
    return handleApiResponse<SearchServiceFileResponse>(response);
  } else {
    return { file: await response.blob() };
  }
}
