import handleApiResponse from "../../core/application/handleApiResponse";

interface UpdateServiceFileParams {
  serviceId: string;
  serviceFileId: string;
}

interface UpdateServiceFilePayload {
  serviceFileCategoryId: string;
}

interface UpdateServiceFileResponse {
  message: string;
}

export default async function updateServiceFile(params: UpdateServiceFileParams, payload: UpdateServiceFilePayload): Promise<UpdateServiceFileResponse> {
  const response = await fetch(`/api/service/${params.serviceId}/file/${params.serviceFileId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  return handleApiResponse<UpdateServiceFileResponse>(response);
}
