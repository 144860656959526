import handleApiResponse from "../../core/application/handleApiResponse";
import ServiceExpert from "../domain/serviceExpert";

interface params {
  serviceId: string;
}

interface ServiceExpertResponse {
  experts: ServiceExpert[];
}

export async function readServiceExpertCollectionByServiceId(params: params): Promise<ServiceExpertResponse> {
  const response = await fetch(`/api/service/${params.serviceId}/expert`, {
    method: "GET",
  });

  return handleApiResponse<ServiceExpertResponse>(response);
}
