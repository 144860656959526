import { readServiceExpertCollectionByServiceId } from "../../../../api/services/application/readServiceExpertCollectionByServiceId";
import ServiceExpert from "../../../../api/services/domain/serviceExpert";

export default async function searchIfUserIsExpertFromTheService(serviceId: string): Promise<boolean> {
  try {
    const response = await readServiceExpertCollectionByServiceId({ serviceId });

    if (!Array.isArray(response) || response.length === 0) {
      return false;
    }

    const session = AppGbSession.getSessionData();
    if (!session) {
      return false;
    }

    return response.some((expert: ServiceExpert) => expert.userId === session.userId);
  } catch (error) {
    console.error("Error al verificar si el usuario es un experto en el servicio:", error);
    return false;
  }
}
