import serviceTypeIds from "../domain/serviceTypeIds";

interface ServiceAccessCheckers {
  financialEducationServiceAccessChecker?: string;
  spadServiceAccessChecker?: string;
  pymeServiceAccessChecker?: string;
  cpServiceAccessChecker?: string;
  financialPlanningServiceAccessChecker?: string;
}

interface ServiceCheckFunctions {
  financialEducationServiceCheckFunction?: () => Promise<boolean> | boolean;
  spadServiceCheckFunction?: () => Promise<boolean> | boolean;
  pymeServiceCheckFunction?: () => Promise<boolean> | boolean;
  cpServiceCheckFunction?: () => Promise<boolean> | boolean;
  financialPlanningCheckFunction?: () => Promise<boolean> | boolean;
}

export default async function checkPermissionsForServiceType({ serviceType, accessCheckers = {}, checkFunctions = {} }: { serviceType: string; accessCheckers?: ServiceAccessCheckers; checkFunctions?: ServiceCheckFunctions }): Promise<boolean> {
  switch (serviceType) {
    case serviceTypeIds.financialEducationService: {
      if (!accessCheckers.financialEducationServiceAccessChecker && !checkFunctions.financialEducationServiceCheckFunction) {
        throw new Error("You must provide an accessChecker for this service type");
      }
      if (AppGbSession.checkUserHasPermission(accessCheckers.financialEducationServiceAccessChecker!)) {
        return true;
      }
      if (typeof checkFunctions.financialEducationServiceCheckFunction === "function") {
        return await checkFunctions.financialEducationServiceCheckFunction();
      }
      return false;
    }
    case serviceTypeIds.spadService: {
      if (!accessCheckers.spadServiceAccessChecker && !checkFunctions.spadServiceCheckFunction) {
        throw new Error("You must provide an accessChecker for this service type");
      }
      if (AppGbSession.checkUserHasPermission(accessCheckers.spadServiceAccessChecker!)) {
        return true;
      }
      if (typeof checkFunctions.spadServiceCheckFunction === "function") {
        return await checkFunctions.spadServiceCheckFunction();
      }
      return false;
    }
    case serviceTypeIds.pymeService: {
      if (!accessCheckers.pymeServiceAccessChecker && !checkFunctions.pymeServiceCheckFunction) {
        throw new Error("You must provide an accessChecker for this service type");
      }
      if (AppGbSession.checkUserHasPermission(accessCheckers.pymeServiceAccessChecker!)) {
        return true;
      }
      if (typeof checkFunctions.pymeServiceCheckFunction === "function") {
        return await checkFunctions.pymeServiceCheckFunction();
      }
      return false;
    }
    case serviceTypeIds.cpService: {
      if (!accessCheckers.cpServiceAccessChecker && !checkFunctions.cpServiceCheckFunction) {
        throw new Error("You must provide an accessChecker for this service type");
      }
      if (AppGbSession.checkUserHasPermission(accessCheckers.cpServiceAccessChecker!)) {
        return true;
      }
      if (typeof checkFunctions.cpServiceCheckFunction === "function") {
        return await checkFunctions.cpServiceCheckFunction();
      }
      return false;
    }
    case serviceTypeIds.financialPlanningService: {
      if (!accessCheckers.financialPlanningServiceAccessChecker && !checkFunctions.financialPlanningCheckFunction) {
        throw new Error("You must provide an accessChecker for this service type");
      }
      if (AppGbSession.checkUserHasPermission(accessCheckers.financialPlanningServiceAccessChecker!)) {
        return true;
      }
      if (typeof checkFunctions.financialPlanningCheckFunction === "function") {
        return await checkFunctions.financialPlanningCheckFunction();
      }
      return false;
    }
    default:
      throw new Error("The service type provided is not valid");
  }
}
