import ApiRequestError from "../../../../api/core/domain/apiRequestError";
import searchServiceFileCategoriesByServiceType from "../../../../api/services/application/searchServiceFileCategoriesByServiceType";
import { ServiceFileCategory } from "../../../../api/services/domain/serviceFileCategory";

export default async function searchServiceFileCategoriesByServiceTypeId(serviceTypeId: string): Promise<ServiceFileCategory[]> {
  try {
    return await searchServiceFileCategoriesByServiceType({ serviceTypeId });
  } catch (error) {
    if (error instanceof ApiRequestError && error.status === 404) return [];

    console.error("No se encontraron categorias de archivos de servicio para el servicio con ID:", serviceTypeId);
    return [];
  }
}
