import useElementLoaderForElement from "../../../shared/application/loaders/useElementLoaderForElement";
import openServiceFileModal from "./openServiceFileModal";

const DOM_SELECTORS = {
  showServiceDocumentModal: "showServiceDocumentModal",
};

function initServiceFileModalEventListeners(): void {
  if (
    !/^\/herramientas\/servicio\/[a-f0-9-]{36}\/?$/.test(window.location.pathname) &&
    !/^\/herramientas\/companyservice\/?$/.test(window.location.pathname) &&
    !/^\/herramientas\/financialeducationservice\/?$/.test(window.location.pathname) &&
    !/^\/herramientas\/spad\/?$/.test(window.location.pathname) &&
    !/^\/herramientas\/commercial-products\/?$/.test(window.location.pathname)
  ) {
    return;
  }

  document.body.addEventListener("click", async (event) => {
    const target = event.target as HTMLElement;

    if (target.hasAttribute(DOM_SELECTORS.showServiceDocumentModal)) {
      const loader = useElementLoaderForElement({
        element: target,
        loadingText: "",
        minLoadSeconds: 1,
        autoStopSeconds: 1,
      });

      loader.start();

      const serviceId = target.getAttribute("data-service-id");
      const serviceTypeId = target.getAttribute("data-service-type");

      if (!serviceId || !serviceTypeId) {
        console.error("Service ID or Service Type ID not found.");
        return;
      }
      try {
        await openServiceFileModal(serviceId, serviceTypeId);
      } catch (error) {
        console.error("Error opening service file modal:", error);
        loader.stop();
      } finally {
        loader.stop();
      }
    }
  });
}

window.addEventListener("load", initServiceFileModalEventListeners);
