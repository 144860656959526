import handleApiResponse from "../../core/application/handleApiResponse";
import ServiceFile from "../domain/serviceFile";

interface SearchServiceFileListParams {
  serviceId: string;
}

export default async function searchServiceListFile(params: SearchServiceFileListParams): Promise<ServiceFile[]> {
  const response = await fetch(`/api/service/${params.serviceId}/files`);
  return handleApiResponse<ServiceFile[]>(response);
}
