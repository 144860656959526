export function todayFormattedAsYYYYMMDD(): string {
  const today: Date = new Date();
  const year: number = today.getFullYear();
  const month: string = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JS
  const day: string = String(today.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function formatDateToSpanishShortDate(date: Date): string {
  return date.toLocaleString("es-ES", { dateStyle: "short", timeStyle: "short" });
}

