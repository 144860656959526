import ApiRequestError from "../../../../api/core/domain/apiRequestError";
import searchServiceFile from "../../../../api/services/application/searchServiceFile";

export default async function downloadServiceFileById({ serviceId, serviceFileId }: { serviceId: string; serviceFileId: string }): Promise<Blob | null> {
  let serviceFile: Blob | null;
  try {
    const response = await searchServiceFile({ serviceId, serviceFileId });

    if (response.message) {
      console.error(`No se ha podido descargar el archivo ${serviceFileId} en el servicio con ID: ${serviceId} - ${response.message}`);
      return null;
    }

    if (!response.file) {
      console.error(`El archivo ${serviceFileId} no existe en el servicio con ID: ${serviceId}`);
      return null;
    }

    serviceFile = response.file;
  } catch (error) {
    if (error instanceof ApiRequestError && error.status === 404) return null;

    console.error(`No se ha podido descargar el archivo ${serviceFileId} en el servicio con ID: ${serviceId}`);
    return null;
  }

  return serviceFile;
}
