import ApiRequestError from "../../../../api/core/domain/apiRequestError";
import serviceFileUpdater from "../../../../api/services/application/updateServiceFile";

interface UpdateServiceFileParams {
  serviceId: string;
  serviceFileId: string;
}

interface UpdateServiceFilePayload {
  serviceFileCategoryId: string;
}

export default async function updateServiceFile(params: UpdateServiceFileParams, payload: UpdateServiceFilePayload): Promise<string | null> {

  let responseMessage: string;
  try {

    const response = await serviceFileUpdater({ serviceId: params.serviceId, serviceFileId: params.serviceFileId }, { serviceFileCategoryId: payload.serviceFileCategoryId });
    responseMessage = response.message;

  } catch (error) {
    if (error instanceof ApiRequestError && error.status === 404) return null;

    console.error(`No se ha encontrado el archivo con ID ${params.serviceFileId} de servicio con ID: ${params.serviceId}`);
    return null;
  }

  return responseMessage;
}
