"use strict";

import { isValidUuid } from "../shared/shared.js";

import pymesService from "./pyme_service_tool.js";
import spadService from "./spad_service.js";

import { readServiceCustomer, readServiceExpert } from "../../api/service.js";
import { loadAndOpenRegisterCustomerModal, loadAndOpenUnregisterCustomerModal } from "./service_customer.js";
import { getServiceExperts, loadAndOpenRegisterExpertModal } from "./service_experts.js";
import { addServiceVisibilityEventListener } from "./services_visibility.js";

const serviceTypeUUID = {
  financialEducationService: "d9ba07e1-1b55-45c5-bd4c-df948a454b6b",
  financialPlanningService: "114e2a53-a541-46a1-a476-e818b5b9b341",
  spadService: "f14ff770-17a0-4a75-bead-3b50af654a96",
  pymeService: "32ed1b24-6065-488f-92d2-e18d5ce03455",
  cpService: "bdec70d2-e4d7-4cbb-b0f7-16abd96dd82c",
};

function getUpdateServiceModalData() {
  $("#load-service-modal-updater").removeClass("d-none");
  $("#data-service-modal-updater").addClass("d-none");

  let serviceId = $("#service-id").val();

  $.ajax({
    url: "/herramientas/services/ajax/obtainUpdaterModalDataByServiceType",
    type: "POST",
    data: {
      serviceId: serviceId,
    },
    async: true,
  })
    .done(function (data) {
      const response = JSON.parse(data);
      if (response["status"] === 1) {
        const modaBody = $("#data-service-modal-updater");
        //clean modalBody
        modaBody.empty();

        response.data.formData.forEach((element) => {
          switch (element.type) {
            case "select": {
              modaBody.append(`<label for="${element.id}" ${element.disabled ? 'class="text-black-50" data-toggle="tooltip" data-placement="top" title="Imposible modificar"' : ""}>${element.label}</label>`);

              let select = $(
                `<select
                  class="form-control selectpicker show-tick input-lg modal-selectpicker"
                  data-live-search="true"
                  data-live-search-normalize="true"
                  data-size="10"
                  data-dropup-auto="false"
                  id="${element.id}"
                  ${element.required ? "required" : ""}
                  ${element.disabled ? "disabled" : ""}
                >
                </select>`
              );

              element.options.forEach((option) => {
                select.append(`<option value="${option.value}" ${option.selected ? "selected" : ""}>${option.label}</option>`);
              });
              modaBody.append(select);
              break;
            }
            case "input":
              modaBody.append(`<label for="${element.id}">${element.label}</label>`);
              modaBody.append(`<input type="text" class="form-control" id="${element.id}" value="${element.options[0].value}">`);
              break;
            case "datetime-local":
              modaBody.append(`<label for="${element.id}">${element.label}</label>`);
              modaBody.append(`<input type="datetime-local" class="form-control" id="${element.id}" value="${element.options[0].value}">`);
              break;
            case "textarea":
              modaBody.append(`<label for="${element.id}">${element.label}</label>`);
              modaBody.append(`<textarea class="form-control" id="${element.id}" rows="3">${element.options[0].value}</textarea>`);
              break;
            default:
              break;
          }
        });

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
          $(".selectpicker").selectpicker({
            mobile: true,
          });
        } else {
          $(".selectpicker").selectpicker({
            container: "body",
          });
        }

        $('[data-toggle="tooltip"]').tooltip();

        $("#load-service-modal-updater").addClass("d-none");
        $("#data-service-modal-updater").removeClass("d-none");

        return true;
      } else {
        return false;
      }
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

function updateService() {
  $("#btn-update-service").attr("disabled", true);
  $("#btn-update-service").html(` <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>` + ` Actualizando...`);

  let serviceId = $("#service-id");

  if (serviceId.val()) {
    var formData = new FormData();

    formData.append("serviceId", serviceId.val() ? serviceId.val() : "");

    $("#data-service-modal-updater")
      .find("select,input,textarea")
      .each(function () {
        let element = $(this);

        if (element.val() === "") {
          return true;
        }

        formData.append(element.attr("id"), element.val());
      });
    $.ajax({
      url: "/herramientas/services/ajax/updateService",
      type: "POST",
      dataType: "html",
      data: formData,
      processData: false,
      contentType: false,
      async: true,
    })
      .done(function (data) {
        const response = JSON.parse(data);

        if (response["status"] === 1) {
          $("#modal-update-service").modal("hide");
          getServiceData();
          createToast("success", "Servicio actualizado", "El servicio ha sido actualizado correctamente", 5000);
        } else {
          if (response["error"]["message"] == "Invalid Identification Number") {
            response["error"]["message"] = "El NIF no es válido";
          }
          createToast("warning", "Ha ocurrido un error", response["error"]["message"], 5000);
        }
        $("#btn-update-service").removeAttr("disabled");
        $("#btn-update-service").html(`Actualizar`);
        $("#service-update").removeClass("was-validated");
      })
      .fail(function (e) {
        if (e.status === 401) {
          createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
          setTimeout(function () {
            location.reload();
          }, 5000);
        } else {
          createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
        }
      });
  }
}

async function getServiceData() {
  $("#load-service-data-container").removeClass("d-none");
  $("#data-service-data-container").addClass("d-none");

  let serviceId = $("#service-id").val();

  return new Promise((resolve) => {
    $.ajax({
      url: "/herramientas/services/ajax/getInformationServiceByServiceId",
      type: "POST",
      data: {
        serviceId: serviceId,
      },
      async: true,
    })
      .done(function (data) {
        const response = JSON.parse(data);

        if (response["status"] === 1) {
          document.getElementById("data-service-data-container").innerHTML = "";
          document.getElementById("data-service-data-container").insertAdjacentHTML("beforeend", response["data"]["providerHtml"]);

          printDataByServiceProvider(response);

          response["data"]["canBeModified"] ? $("#buton-update-service")?.attr("data-toggle", "modal").removeClass("btn-barymont-grey").addClass("btn-barymont-red") : $("#buton-update-service")?.removeAttr("data-toggle").removeClass("btn-barymont-red").addClass("btn-barymont-grey");
          response["data"]["canBeDeleted"] ? $("#buton-delete-service")?.removeAttr("data-toggle").attr("data-toggle", "modal").attr("data-target", "#modal-delete-service").removeClass("btn-barymont-grey").addClass("btn-barymont-black") : $("#buton-delete-service")?.removeAttr("data-toggle").attr("data-toggle", "tooltip").attr("data-placement", "top").attr("title", "No se puede eliminar").removeClass("btn-barymont-black").addClass("btn-barymont-grey");
          response["data"]["canSeeDocuments"] ? '' : $("#buton-open-document-modal-service")?.remove();

          $("#load-service-data-container").addClass("d-none");
          $("#data-service-data-container").removeClass("d-none");

          resolve(response["data"]["service"]["serviceTypeId"]);
        } else {
          createToast("danger", "Ha ocurrido un error", "No se han podido cargar los datos del servicio, ponte en contacto con el administrador", 30000);
        }
      })
      .fail(function (e) {
        if (e.status === 401) {
          createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
          setTimeout(function () {
            location.reload();
          }, 5000);
        } else {
          createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
        }
      });
  });
}

function printDataByServiceProvider(response) {
  switch (response["data"]["providerType"]) {
    case "FINANCIAL_EDUCATION_SERVICE": {
      $("#service-breadcrumb").attr("href", "/herramientas/financialeducationservice");
      $("#service-back-btn").attr("href", "/herramientas/financialeducationservice");

      if (response["data"]["service"]["is_used"]) {
        $("#fecha-utilizado-icon").attr("data-original-title", "Utilizado");
        $("#fecha-utilizado-icon > span").addClass("bg-success");
        $("#fecha-utilizado-icon > span > i").addClass("fa-check  text-white").removeClass("fa-times");
      }

      $("#codigo-canjeo").val(response["data"]["service"]["code"]);
      $("#fecha-utilizado").val(response["data"]["service"]["used_at"]);
      $("#plan").val(response["data"]["service"]["plan"]);
      $("#metodo-obtencion").val(response["data"]["service"]["obtain_method"]);
      $("#tipo-periodicidad").text(response["data"]["service"]["periodicity"]);
      $("#fecha-creacion").val(response["data"]["service"]["created_at"]);
      $("#fecha-actualizacion").val(response["data"]["service"]["modified_at"]);
      $("#fecha-expiracion").val(response["data"]["service"]["expired_at"]);

      $("#importe-neto").val(response["data"]["service"]["net_amount"] / 100);
      $("#importe-bruto").val(response["data"]["service"]["gross_amount"] / 100);
      $("#tipo-servicio").val(response["data"]["service"]["service_type_provider_label"]);
      $("#educator-name").val(response["data"]["service"]["educator_name"]);
      $("#educator-mode").html(response["data"]["service"]["educator_mode"]);

      $("#seller-nif").val(response["data"]["service"]["seller_nif"]);
      $("#seller-name").val(response["data"]["service"]["seller_name"] ?? "BÁRYMONT");

      document.getElementById("buton-update-service")?.addEventListener("click", getUpdateServiceModalData);

      break;
    }

    case "SPAD_FACTURA_TELEFONICA":
    case "SPAD_FACTURA_GAS":
    case "SPAD_SEGURIDAD_EN_EMPRESAS_Y_DOMICILIOS_ALARMAS":
    case "SPAD_RETRASOS_VUELOS":
    case "SPAD_AHORRO_IMPUESTO_IBI":
    case "SPAD_AHORRO_FACTURA_LUZ":
    case "SPAD_DEFENSA_JURIDICA":
    case "SPAD_ASESORAMIENTO_HIPOTECARIO":
    case "SPAD_ASESORAMIENTO_HERENCIAS": {
      $("#service-breadcrumb").attr("href", "/herramientas/spad");
      $("#service-back-btn").attr("href", "/herramientas/spad");

      let additionalData = JSON.parse(response["data"]["service"]["data"]);
      $("#service-type").val(response["data"]["service"]["servideTypeName"]);
      $("#spad-service-name").val(response["data"]["service"]["serviceType"]["name"]);
      $("#spad-service-tracking-id").text(response["data"]["service"]["trackingId"]);
      $("#spad-service-state").text(response["data"]["service"]["state"]["label"]).addClass(response["data"]["service"]["state"]["background"]).addClass(response["data"]["service"]["state"]["textColor"]);
      $("#spad-service-descripcion").val(response["data"]["service"]["serviceType"]["description"]);
      $("#spad-service-observations").text(additionalData.observaciones);
      $("#spad-service-created-at").val(response["data"]["service"]["createdAt"]);
      $("#spad-service-modify-at").val(response["data"]["service"]["modifiedAt"]);

      $("#spad-service-request-user-name").val(response["data"]["service"]["userName"]);

      Object.entries(additionalData).forEach(([element, value]) => {
        if (element === "observaciones") {
          return;
        }

        document.getElementById("spad-service-additional-data").insertAdjacentHTML(
          "beforeend",
          `<div class="col-6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text bg-light">${element}</span>
              </div>
              <input type="text" class="form-control" value="${value}" readonly>
            </div>
          </div>`
        );
      });

      let btnUpdateServiceSpad = document.getElementById("buton-update-service");

      btnUpdateServiceSpad?.removeAttribute("data-toggle");
      btnUpdateServiceSpad?.removeAttribute("data-target");
      btnUpdateServiceSpad?.addEventListener("click", spadService.loadAndOpenChangeStateModal);

      document.getElementById("spad-service-hystorical-btn").addEventListener("click", spadService.loadAndOpenHistoricalModal);

      if (response["data"]["service"]["serviceProvider"] === null) {
        $("#spad-service-provider-container-data").html(`
          <div class="col-12 text-center px-0 mt-3">
            <span class="badge badge-light w-100 py-3" style="font-size:14px">Todavia no hay proveedor asociado</span>
          </div>
        `);
      } else {
        $("#spad-service-provider-name").val(response["data"]["service"]["serviceProvider"]["name"]);
      }

      break;
    }
    case "PYME_SERVICE": {
      $("#service-breadcrumb").attr("href", "/herramientas/companyservice");
      $("#service-back-btn").attr("href", "/herramientas/companyservice");

      $("#service-type").val(response["data"]["service"]["serviceTypeLabel"]);
      $("#pyme-service-name").val(response["data"]["service"]["serviceTypeName"]);
      $("#pyme-service-tracking-id").text(response["data"]["service"]["trackingId"]);
      $("#pyme-service-state").text(response["data"]["service"]["stateType"]["label"]).addClass(response["data"]["service"]["stateType"]["backgroundColor"]).addClass(response["data"]["service"]["stateType"]["textColor"]);
      $("#pyme-service-observations").text(response["data"]["service"]["data"]);
      $("#pyme-service-created-at").val(response["data"]["service"]["createdAt"]);
      $("#pyme-service-modify-at").val(response["data"]["service"]["modifiedAt"]);

      $("#pyme-service-request-user-name").val(response["data"]["service"]["userName"]);

      let btnUpdateServicePymes = document.getElementById("buton-update-service");
      btnUpdateServicePymes?.removeAttribute("data-toggle");
      btnUpdateServicePymes?.removeAttribute("data-target");
      btnUpdateServicePymes?.addEventListener("click", pymesService.loadAndOpenUpdateServiceModal);

      document.getElementById("pyme-service-hystorical-btn").addEventListener("click", pymesService.loadAndOpenHistoricalModal);

      const serviceId = response["data"]["service"]["serviceId"];

      if (!AppGbSession.checkUserHasPermission("PymeService:PymeServiceUpdateAccessChecker")) {
        document.getElementById("pyme-service-change-state-btn")?.remove();
      }

      document.getElementById("pyme-service-change-state-btn")?.addEventListener("click", async () => {
        const pymeServiceAddStateModal = "pyme-service-change-state-modal";
        await pymesService.openAddNewStateModal(serviceId, pymeServiceAddStateModal);
        getServiceData();
      });

      if (response["data"]["service"]["serviceProvider"] === null) {
        $("#pyme-service-provider-container-data").html(`
          <div class="col-12 text-center px-0 mt-3">
            <span class="badge badge-light w-100 py-3" style="font-size:14px">Todavia no hay proveedor asociado</span>
          </div>
        `);
      } else {
        $("#pyme-service-provider-name").val(response["data"]["service"]["serviceProviderName"]);
      }

      readServiceExpert(serviceId).then((experts) => {
        getServiceExperts({ serviceId, experts, permissionKey: "PymeService:PymeServiceUpdateAccessChecker" });
      });

      break;
    }

    case "COMMERCIAL_PRODUCT_SERVICE": {
      $("#service-breadcrumb").attr("href", "/herramientas/commercial-products");
      $("#service-back-btn").attr("href", "/herramientas/commercial-products");

      $("#service-type").val(response["data"]["service"]["serviceType"]["label"]);
      $("#cp-service-tracking-id").html(response["data"]["service"]["trackingId"]);
      $("#cp-service-description").html(response["data"]["service"]["description"] ?? "Sin observaciones");
      $("#cp-service-created-at").val(response["data"]["service"]["createdAt"]);
      $("#cp-service-modify-at").val(response["data"]["service"]["modifiedAt"]);
      $("#cp-service-policy-number").val(response["data"]["service"]["ebrokerPolicyNumber"]);
      $("#cp-service-insurance-company").val(response["data"]["service"]["ebrokerCompanyName"]);

      const recibos = JSON.parse(response["data"]["service"]["data"]).ebroker_receipt_ordinal_codes || [];

      const recibosIds = Object.keys(recibos);

      $("#cp-service-recipt-list").html(`<button type="button" id="view-receipt-codes-btn" class="btn btn-barymont-red w-100 mb-2" ${recibosIds.length > 0 ? "" : "disabled"}>Ver códigos de recibos procesados</button>`);

      document.getElementById("view-receipt-codes-btn").addEventListener("click", function () {
        showRecibosModal(recibosIds);
      });

      document.getElementById("buton-update-service")?.addEventListener("click", getUpdateServiceModalData);

      break;
    }

    case "FINANCIAL_PLANNING_SERVICE": {
      let serviceId = $("#service-id").val();

      // TODO: We need to format the template correctly with data
      $("#service-data-friendly-id").val(response["data"]["service"]["friendlyId"]);
      $("#service-data-promoter-user-id").val(response["data"]["service"]["promoterUserId"]);
      $("#service-data-stripe-subscription-id").val(response["data"]["service"]["stripeSubscriptionId"]);
      $("#service-data-stripe-customer-id").val(response["data"]["service"]["stripeCustomerId"]);

      readServiceExpert(serviceId).then((experts) => {
        getServiceExperts({ serviceId, experts, permissionKey: "FinancialPlanningService:FinancialPlanningServiceManageAccessChecker" });
      });

      break;
    }

    default:
      createToast("warning", "Ha ocurrido un error", "El servicio al que intentas acceder todavia no esta soportado en gestibarymont, ponte en contacto con soporte", 5000);
      break;
  }
}

async function getServiceCustomers(serviceId, serviceTypeId) {
  document.getElementById("customers-data").innerHTML = `
    <div class="col-12 text-center my-4" id="customers-data-spinner">
      <span class="spinner-border text-barymont-red" role="status" aria-hidden="true"></span>
    </div>`;

  const customers = await readServiceCustomer(serviceId);

  switch (serviceTypeId) {
    case serviceTypeUUID.financialEducationService:
      printCustomerDataByServiceProvider(customers, serviceId, true, "FinancialEducationService:FinancialEducationServiceUpdateAccessChecker");
      break;
    case serviceTypeUUID.spadService:
      printCustomerDataByServiceProvider(customers, serviceId, false, "SpadService:SpadServiceUpdateAccessChecker");
      break;
    case serviceTypeUUID.pymeService:
      printCustomerDataByServiceProvider(customers, serviceId, false, "PymeService:PymeServiceUpdateAccessChecker");
      break;
    case serviceTypeUUID.financialPlanningService:
      printCustomerDataByServiceProvider(customers, serviceId, false, "FinancialPlanningService:FinancialPlanningServiceManageAccessChecker");
      break;
    default:
      document.getElementById("customers-container").remove();
      break;
  }

  document.getElementById("customers-data-spinner")?.remove();
}

function printCustomerDataByServiceProvider(response, serviceId, showDeleteButtonOnMainLead, permissionKey) {
  if (response.length > 0) {
    $("#customers-data").append(`<div class="col-12" id="customers-data-container">`);

    // Sort customers to show main customer first
    response.sort((a, b) => (a["isMain"] === b["isMain"] ? 0 : a["isMain"] ? -1 : 1));

    response.forEach((customer) => {
      const { leadId, fullName, isMain } = customer;
      const userHasPermission = AppGbSession.checkUserHasPermission(permissionKey);
      const deleteButtonHtml = getDeleteButtonHtml(customer, response, userHasPermission, showDeleteButtonOnMainLead);

      $("#customers-data-container").append(`
        <div class="input-group my-3">
          <div class="input-group-prepend">
          ${
            customer.hasVisibility
              ? `
              <a href="/herramientas/leads/${leadId}" target="_blank">
                <button class="btn btn-barymont-black" data-toggle="tooltip" data-placement="top" title="Ver ficha del Cliente">
                  <i class="fa fa-user"></i>
                </button>
              </a>
            `
              : `
              <button class="btn btn-barymont-grey" data-toggle="tooltip" data-placement="top" title="Cliente no visible">
                <i class="fa fa-user-slash"></i>
              </button>`
          }
          </div>
          <input type="text" class="form-control" value="${fullName}" readonly>

          <div class="input-group-append">
            <span class="input-group-text bg-light ${isMain ? "font-weight-bold" : ""}" style="width: 90px;">
              ${isMain ? "Principal" : "Secundario"}
            </span>
            ${deleteButtonHtml}
          </div>
        </div>
      `);

      const btnDelete = document.querySelector(`#btn-service-delete-customer-${leadId}`);
      if (btnDelete) {
        btnDelete.addEventListener("click", async () => {
          await loadAndOpenUnregisterCustomerModal(serviceId, leadId);
        });
      }
    });
  } else {
    $("#customers-data").append(`<div class="col-12 my-4 text-center">No hay clientes asociados a este servicio</div>`);
  }
}

function getDeleteButtonHtml(customer, customersList, userHasPermission, showDeleteButtonOnMainLead) {
  const { leadId, isMain } = customer;
  const totalCustomers = customersList.length;

  if (!userHasPermission) {
    return "";
  }

  const redButtonHtml = `
    <button
      class="btn btn-barymont-red"
      data-toggle="tooltip"
      data-placement="top"
      style="width: 34px;"
      id="btn-service-delete-customer-${leadId}"
      title="Eliminar cliente"
    >
      <i class="fa fa-trash"></i>
    </button>
  `;

  const greyButtonHtml = `
    <button
      class="btn btn-barymont-grey"
      data-toggle="tooltip"
      data-placement="top"
      style="width: 34px;"
      title="No es posible eliminar el cliente principal si el servicio tiene clientes secundarios"
    >
      <i class="fa fa-times"></i>
    </button>
  `;

  if (!isMain) {
    return redButtonHtml;
  }

  if (!showDeleteButtonOnMainLead) {
    return "";
  }

  if (totalCustomers > 1) {
    return greyButtonHtml;
  }

  return redButtonHtml;
}

function showRecibosModal(recibos) {
  const modalId = `modal-${Math.random().toString(36).substring(2, 10)}`;
  const modalHTML = `
    <div class="modal fade" id="${modalId}" tabindex="-1" role="dialog" aria-labelledby="${modalId}-label" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="${modalId}-label">Códigos de recibos procesados</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body scroll-barymont-red" style="max-height: 70vh; overflow-y: auto;">
            <div class="input-group mb-3">
              <input type="text" id="recibo-search-${modalId}" class="form-control" placeholder="Buscar código de recibo...">
              <div class="input-group-append">
                <button type="button" id="clear-search-${modalId}" class="btn btn-outline-danger" data-toggle="tooltip" title="Limpiar búsqueda"><i class="fas fa-times"></i></button>
              </div>
            </div>
            <div class="row" id="recibo-list-${modalId}">
              ${recibos
                .map(
                  (recibo) => `
                <div class="col-12 col-md-4 mb-3 recibo-card">
                  <div class="badge badge-secondary w-100 font-weight-bold recibo-text" style="font-size:16px" data-original-text="${recibo}">${recibo}</div>
                </div>
              `
                )
                .join("")}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-barymont-black" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>`;

  document.body.insertAdjacentHTML("beforeend", modalHTML);
  $('[data-toggle="tooltip"]').tooltip();

  const modalElement = $(`#${modalId}`).modal("show");
  modalElement.on("hidden.bs.modal", () => modalElement.remove());

  const searchInput = document.getElementById(`recibo-search-${modalId}`);
  const clearButton = document.getElementById(`clear-search-${modalId}`);
  const reciboCards = document.querySelectorAll(`#recibo-list-${modalId} .recibo-card`);

  const updateReciboList = () => {
    const searchText = searchInput.value.toLowerCase();
    let hasVisibleCards = false;

    reciboCards.forEach((card) => {
      const reciboTextElement = card.querySelector(".recibo-text");
      const originalText = reciboTextElement.getAttribute("data-original-text");

      if (originalText.toLowerCase().includes(searchText)) {
        reciboTextElement.innerHTML = searchText ? originalText.replace(new RegExp(searchText, "gi"), (match) => `<span style="background-color: #ffff88; font-weight: bold; color: #000; border-radius: 3px;">${match}</span>`) : originalText;
        card.style.display = "block";
        hasVisibleCards = true;
      } else {
        card.style.display = "none";
      }
    });

    const noResultsMessage = document.querySelector(`#recibo-list-${modalId} .no-results`);
    if (!hasVisibleCards) {
      if (!noResultsMessage) {
        document.getElementById(`recibo-list-${modalId}`).insertAdjacentHTML("beforeend", `<div class="col-12 text-center text-muted no-results">No se han encontrado resultados</div>`);
      }
    } else if (noResultsMessage) {
      noResultsMessage.remove();
    }
  };

  searchInput.addEventListener("input", updateReciboList);
  clearButton.addEventListener("click", () => {
    searchInput.value = "";
    updateReciboList();
  });
}

function deleteService() {
  let serviceId = $("#service-id");
  $("#btn-delete-service").attr("disabled", "disabled");
  $("#btn-delete-service").html(`<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Eliminando...`);

  if (serviceId.val()) {
    var formData = new FormData();

    formData.append("serviceId", serviceId.val() ? serviceId.val() : "");

    $.ajax({
      url: "/herramientas/services/ajax/deleteService",
      type: "POST",
      dataType: "html",
      data: formData,
      processData: false,
      contentType: false,
      async: true,
    })
      .done(function (data) {
        const response = JSON.parse(data);
        if (response["status"] === 1) {
          window.location = "/herramientas/financialeducationservice";
        } else {
          createToast("warning", "Ha ocurrido un error", response["error"]["message"], 5000);
        }
        $("#btn-delete-service").removeAttr("disabled");
        $("#btn-delete-service").html(`Eliminar`);
      })
      .fail(function (e) {
        if (e.status === 401) {
          createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
          setTimeout(function () {
            location.reload();
          }, 5000);
        } else {
          createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
        }
      });
  }
}

function getTransactionsByServiceId(serviceTypeId) {
  $("#load-transactions-data-container").removeClass("d-none");
  $("#data-transactions-data-container").addClass("d-none");

  $("#transactions-data").html(``);

  let serviceId = $("#service-id").val();
  $.ajax({
    url: "/herramientas/services/ajax/getTransactionsByServiceId",
    type: "POST",
    data: {
      serviceId: serviceId,
    },
    async: true,
  })
    .done(function (data) {
      const response = JSON.parse(data);

      if (response["status"] === 1) {
        if (response["data"].length > 0) {
          $("#transactions-data").html("");
          response["data"].forEach((transaction) => {
            $("#transactions-data").append(`
            <div class="col-12 text-center" style="min-width: 415px;">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"data-toggle="tooltip"
                  data-placement="right"
                  title="${transaction.type}">
                  <i class="fas fa-solid ${transaction.type_icon}"></i>
                  </span>
                </div>
                <input type="text"
                class="form-control font-weight-bolder ${transaction.gross_amount < 0 ? "text-danger" : ""}""
                  value="${transaction.gross_amount / 100} €"
                  style="max-width: 85px"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Importe bruto"
                  readonly
                >

                <input type="text"
                  class="form-control font-weight-bolder ${transaction.net_amount < 0 ? "text-danger" : ""}"
                    value="${transaction.net_amount / 100} €"
                    style="max-width: 85px"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Importe neto"
                    readonly
                >

                <input type="text"
                  class="form-control font-weight-bolder"
                  style="max-width: 55px"
                  value="${transaction.tax_percent / 100}%"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="% de impuestos"
                  readonly
                >

                <input type="text"
                  class="form-control font-weight-bolder"
                  value="${transaction.created_at.slice(0, -3)}"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Fecha de creación"
                  readonly
                >

                <div class="input-group-append">
                    <button
                      id="btn-transaction-info"
                      data-transaction-id="${transaction.transaction_id}"
                      class="btn btn-barymont-red px-3"
                      type="button"
                    >
                        <i class="fas fa-info fa-lg"></i>
                    </button>
                  </div>
              </div>
            </div>
          `);

            document.querySelector(`#btn-transaction-info[data-transaction-id="${transaction.transaction_id}"]`)?.addEventListener("click", () => loadTransactionInfo(transaction.transaction_id, serviceTypeId));
          });
        } else {
          $("#transactions-data").append(`
          <div class="col-12 text-center">
            <p>No existen transacciones para este servicio</p>
          </div>
        `);
        }

        $("#load-transactions-data-container").addClass("d-none");
        $("#data-transactions-data-container").removeClass("d-none");

        $('[data-toggle="tooltip"]').tooltip();
        return true;
      } else {
        $("#transactions-data").append(`
        <div class="col-12 text-center">
          <p>${response["error"]["message"]}</p>
        </div>
      `);

        $("#load-transactions-data-container").addClass("d-none");
        $("#data-transactions-data-container").removeClass("d-none");
        return false;
      }
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

function createTransaction() {
  $("#btn-create-transaction").attr("disabled", true);
  $("#btn-create-transaction").html(` <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>` + ` Creando transacción...`);

  let serviceId = $("#service-id");
  let grossAmount = $("#gross_amount");
  let taxPercent = $("#tax_percentaje");
  let netAmount = $("#net_amount");
  let paymentId = $("#payment");
  let operationType = $("#operation");
  let transactionType = $("#transaction-type");

  if (paymentId.val() !== "" && !isValidUuid(paymentId.val())) {
    createToast("error", "Error al crear la transacción", "El ID del pago no es válido", 5000);
    $("#btn-create-transaction").attr("disabled", false);
    $("#btn-create-transaction").html(`Crear`);
    return;
  }

  if (serviceId.val()) {
    var formData = new FormData();

    formData.append("serviceId", serviceId.val() ? serviceId.val() : "");
    formData.append("grossAmount", grossAmount.val() ? grossAmount.val() : "");
    formData.append("taxPercent", taxPercent.val() ? taxPercent.val() : "");
    formData.append("netAmount", netAmount.val() ? netAmount.val() : "");
    formData.append("paymentId", paymentId.val() ? paymentId.val() : "");
    formData.append("operationType", operationType.val() ? operationType.val() : "");

    formData.append("type", transactionType.val() ? transactionType.val() : "");

    $.ajax({
      url: "/herramientas/services/ajax/createTransaction",
      type: "POST",
      dataType: "html",
      data: formData,
      processData: false,
      contentType: false,
      async: true,
    })
      .done(function (data) {
        const response = JSON.parse(data);

        if (response["status"] === 1) {
          getTransactionsByServiceId(response["serviceTypeId"]);

          createToast("success", "Transacción creada", "La transacción se ha creado correctamente", 5000);

          $("#modal-create-transaction").modal("hide");
          resetModalCreateInputs();
        } else {
          createToast("warning", "Ha ocurrido un error", response["error"]["message"], 5000);
        }

        $("#btn-create-transaction").attr("disabled", false);
        $("#btn-create-transaction").html(`Crear transacción`);
        $("#create-transaction").removeClass("was-validated");
      })
      .fail(function (e) {
        if (e.status === 401) {
          createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
          setTimeout(function () {
            location.reload();
          }, 5000);
        } else {
          createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
        }
      });
  }
}

async function loadTransactionInfo(transactionId, serviceTypeId) {
  $("#modal-transaction-info").modal("toggle");
  $("#transaction-id").val(transactionId);

  //load data with ajax
  await loadTransactionUI(serviceTypeId);
  loadTransactionData(transactionId);
  loadTransactionDistributionData(transactionId);
  loadTransactionComisionsData(transactionId);
}

async function loadTransactionUI(serviceTypeId) {
  if (
    await checkPermissionByServiceType(serviceTypeId, {
      financialEducationServiceAccessChecker: "FinancialEducationService:FinancialEducationServiceTransactionManageAccessChecker",
      spadServiceAccessChecker: "SpadService:SpadServiceTransactionManageAccessChecker",
      pymeServiceAccessChecker: "PymeService:PymeServiceTransactionManageAccessChecker",
      cpServiceAccessChecker: "CpService:CpServiceTransactionManageAccessChecker",
      financialPlanningServiceAccessChecker: "FinancialPlanningService:FinancialPlanningServiceManageAccessChecker",
    }) === false
  ) {
    document.getElementById("btn-modify-transaction").style.display = "none";
    document.getElementById("btn-delete-transaction").style.display = "none";
    document.getElementById("btn-modal-make-commission").style.display = "none";
    document.getElementById("btn-modal-make-custom-distribution").style.display = "none";
  }
}

function loadTransactionData(transactionId = null) {
  $("#data-transaction-transaction").addClass("d-none");
  $("#load-transaction-transaction").removeClass("d-none");

  $.ajax({
    url: "/herramientas/services/ajax/readTransaction",
    type: "POST",
    data: {
      transactionId: transactionId ?? $("#transaction-id").val(),
    },
    async: true,
  })
    .done(function (data) {
      const response = JSON.parse(data);
      if (response["status"] === 1) {
        $("#data-transaction-transaction").html(`
      <div class="col-12 text-center px-0">
        <div class="input-group mt-3">
          <div class="input-group-prepend">
              <span class="input-group-text bg-light">ID</span>
          </div>
          <input type="text" class="form-control font-weight-bolder" value="${response.data.transaction_id}" readonly>
        </div>
        <div class="row">
          <div class="input-group mt-3 col-12 col-lg-6">
            <div class="input-group-prepend">
                <span class="input-group-text bg-light">Importe Bruto</span>
            </div>
            <input type="text" class="form-control font-weight-bolder" id="transaction-gross-amount" value="${response.data.gross_amount / 100} €" readonly>
          </div>
          <div class="input-group mt-3 col-12 col-lg-6">
            <div class="input-group-prepend">
                <span class="input-group-text bg-light">Importe Neto</span>
            </div>
            <input type="text" class="form-control font-weight-bolder" value="${response.data.net_amount / 100} €" readonly>
          </div>
        </div>
        <div class="input-group mt-3">
          <div class="input-group-prepend">
              <span class="input-group-text bg-light">% de impuestos</span>
          </div>
          <input type="text" class="form-control font-weight-bolder" value="${response.data.tax_percent / 100} %" readonly>
        </div>
        <div class="input-group mt-3">
          <div class="input-group-prepend">
              <span class="input-group-text bg-light">Tipo operación</span>
          </div>
          <input type="text" class="form-control font-weight-bolder" value="${response.data.operation_type}" readonly>
        </div>
        <div class="input-group mt-3">
          <div class="input-group-prepend">
              <span class="input-group-text bg-light">Tipo de transacción</span>
          </div>
          <input type="text" class="form-control font-weight-bolder" value="${response.data.type}" readonly>
        </div>
        <div class="input-group mt-3">
          <div class="input-group-prepend">
              <span class="input-group-text bg-light">ID Pago</span>
          </div>
          <input type="text" class="form-control font-weight-bolder" value="${response.data.payment_id != null ? response.data.payment_id : ""}" readonly>
        </div>
        <div class="input-group mt-3">
          <div class="input-group-prepend">
              <span class="input-group-text bg-light">Creación</span>
          </div>
          <input type="text" class="form-control font-weight-bolder" value="${response.data.created_at}" readonly>
        </div>
        <div class="input-group mt-3">
          <div class="input-group-prepend">
              <span class="input-group-text bg-light">Modificación</span>
          </div>
          <input type="text" class="form-control font-weight-bolder" value="${response.data.modified_at != null ? response.data.modified_at : ""}" readonly>
          <input type="hidden" id="trasaction-have-commision" value="${response.data.haveCommissions ? 1 : 0}" readonly>
        </div>
      </div>
    `);
        let btnDeleteTransaction = document.getElementById("btn-delete-transaction");
        if (response.data.canBeDeleted) {
          btnDeleteTransaction.classList.remove("btn-barymont-grey");
          btnDeleteTransaction.classList.add("btn-barymont-black");
          btnDeleteTransaction.removeAttribute("disabled");
          btnDeleteTransaction.setAttribute("data-original-title", "Eliminar transacción");
          btnDeleteTransaction.addEventListener("click", () => openModalDeleteTransaction(response.data.transaction_id));
        } else {
          btnDeleteTransaction.classList.remove("btn-barymont-black");
          btnDeleteTransaction.classList.add("btn-barymont-grey");
          btnDeleteTransaction.setAttribute("disabled", true);
          btnDeleteTransaction.setAttribute("data-original-title", "No se puede eliminar");
          btnDeleteTransaction.removeEventListener("click", () => openModalDeleteTransaction(response.data.transaction_id));
        }
      } else {
        $("#data-transaction-transaction").html(`
        <div class="col-12 text-center">
          <p>No se han encontrado datos</p>
        </div>
      `);
      }

      $("#data-transaction-transaction").removeClass("d-none");
      $("#load-transaction-transaction").addClass("d-none");
      $('[data-toggle="tooltip"]').tooltip();
      return true;
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

function openModalDeleteTransaction(transactionId) {
  $("#modal-delete-transaction").modal("toggle");
  $("#data-modal-delete-transaction").html(`
    <div class="alert alert-danger" role="alert">
        Transacción con código: <b>${transactionId}</b><br><br>
        Se va a eliminar la transacción y comisiones asociadas a él. <b>¿Estás seguro de que quieres eliminarla?</b>
    </div>
  `);
}

function loadTransactionDistributionData(transactionId = null, containerId = "transaction-distribution") {
  $("#data-" + containerId).addClass("d-none");
  $("#load-transaction-distribution").removeClass("d-none");

  $("#data-" + containerId + "-unasigned").addClass("d-none");
  $("#load-transaction-distribution-unasigned").removeClass("d-none");

  $.ajax({
    url: "/herramientas/services/ajax/getDistributionByTransactionId",
    type: "POST",
    data: {
      transactionId: transactionId ?? $("#transaction-id").val(),
    },
    async: true,
  })
    .done(function (data) {
      const response = JSON.parse(data);

      if (response["status"] === 1) {
        $("#data-" + containerId).html("");
        $("#data-" + containerId + "-unasigned").html("");
        response["data"].forEach((distribution) => {
          let transactionContainer;
          distribution.isMistmatch ? (transactionContainer = $("#data-" + containerId + "-unasigned")) : (transactionContainer = $("#data-" + containerId));
          transactionContainer.append(`
          <div class="col-12 text-center mt-3 px-0">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span
                  class="input-group-text bg-light"
                  style="min-width:86px;"
                  data-toggle="tooltip"
                  title="${distribution.nifName}"
                  >
                    ${distribution.nif}
                </span>
                <span
                  class="input-group-text bg-light"
                  style="border-radius: 0; min-width:50px;"
                  data-toggle="tooltip"
                  title="Porcentaje obtenido"
                  >
                  ${distribution.percentApplied / 100} %
                </span>
              </div>
              <input
                type="text"
                class="form-control font-weight-bolder"
                value="${distribution.amount / 100} €"
                data-toggle="tooltip"
                title="Importe obtenido por el usuario"
                readonly
              >
              <div class="input-group-append">
              <span
                class="input-group-text bg-light text-barymont-red text-center"
                data-toggle="tooltip"
                title="${distribution.label}">
                <i class="far fa-question-circle" style="font-size:19px"></i>
              </span>
              </div>
            </div>
          </div>
        `);
        });

        if ($("#data-" + containerId + "-unasigned").html() == "") {
          $("#data-" + containerId + "-unasigned").html(`
          <div class="col-12 text-center">
            <p>No hay distribuciones sin asignar</p>
          </div>
        `);
        }

        let btnModalMakeCommission = document.getElementById("btn-modal-make-commission");

        btnModalMakeCommission.setAttribute("data-toggle", "modal");
        btnModalMakeCommission.setAttribute("data-target", "#modal-make-commission");

        btnModalMakeCommission.addEventListener("click", () => loadPreviewCommissions());

        btnModalMakeCommission.classList.remove("btn-barymont-grey");
        btnModalMakeCommission.classList.remove("d-none");
        btnModalMakeCommission.classList.add("btn-barymont-black");

        $("#data-" + containerId).removeClass("d-none");
        $("#load-transaction-distribution").addClass("d-none");

        $("#data-" + containerId + "-error").removeClass("d-none");

        $("#data-" + containerId + "-unasigned").removeClass("d-none");
        $("#load-transaction-distribution-unasigned").addClass("d-none");

        $('[data-toggle="tooltip"]').tooltip();
      } else {
        $("#data-" + containerId + "-error").addClass("d-none");

        $("#data-" + containerId).removeClass("d-none");
        $("#load-transaction-distribution").addClass("d-none");

        $("#data-" + containerId).html(`
          <p class="text-center">${response["error"]["message"]}</p>
      `);

        $("#btn-modal-make-commission").removeAttr("data-toggle").removeAttr("data-target").removeAttr("onclick").removeClass("btn-barymont-black").addClass("btn-barymont-grey").addClass("d-none");
      }
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

function loadTransactionComisionsData(transactionId = null, containerId = "transaction-comisions") {
  $("#data-" + containerId).addClass("d-none");
  $("#load-" + containerId).removeClass("d-none");

  $.ajax({
    url: "/herramientas/services/ajax/getCommissionsByTransactionId",
    type: "POST",
    data: {
      transactionId: transactionId ?? $("#transaction-id").val(),
    },
    async: true,
  })
    .done(function (data) {
      const response = JSON.parse(data);

      if (response.status === 1) {
        $("#data-" + containerId).html("");

        const transactionCommissions = Object.entries(response.data.commission).map(([nif, commissions]) => ({ nif, commissions }));

        if (transactionCommissions.length > 0) {
          $("#btn-modal-make-commission").html('<i class="fa-solid fa-money-bill-wave pr-3"></i> Recomisionado Automático');

          $("#btn-modal-make-custom-distribution").html('<i class="fa-solid fa-money-bill-transfer pr-3"></i> Recomisionado Manual');

          $("#btn-make-commission").text("Recomisionar");
          $("#btn-make-custom-commission").text("Recomisionar");

          transactionCommissions.forEach((nifCommissions) => {
            let allSettled = nifCommissions.commissions.every((commission) => commission.settled === true);

            $("#data-" + containerId).append(`
            <div  id="accordion-${nifCommissions.nif}" class="col-12 text-center mt-3 px-0">
              <div class="card">

                <div class="card-header p-0" id="accordion-heading-${nifCommissions.nif}">
                  <div class="input-group">

                    <div class="input-group-prepend">
                      <span class="input-group-text font-weight-bolder" style="min-width:91px;" >
                        ${nifCommissions.nif}
                      </span>
                      <span class="input-group-text" style="border-radius: 0;">
                        <span
                          id="nif-percentaje-total-${nifCommissions.nif}"
                        >
                        </span>
                        <span
                          id="nif-gross-total-${nifCommissions.nif}" class="pl-1"
                        >
                        </span>
                      </span>
                    </div>

                    <input
                      id="nif-distribution-total-${nifCommissions.nif}"
                      type="text"
                      class="form-control font-weight-bolder text-right"
                      value="0 €"
                      readonly
                    >

                    <div
                      class="input-group-append"
                      style="cursor:pointer"
                      data-toggle="collapse"
                      data-target="#nif-commission-body-${nifCommissions.nif}"
                      aria-expanded="${allSettled ? "false" : "true"}"
                      aria-controls="nif-commission-body-${nifCommissions.nif}"
                    >
                      <span
                        class="input-group-text bg-light text-center text-${allSettled ? "success" : "secondary"}"
                        data-toggle="tooltip"
                        data-html="true"
                        title="Cantidad de comisiones<br>Mostrar / Ocultar comisiones"
                      >
                        <span
                          class="badge badge-${allSettled ? "success" : "secondary"} mr-2"
                        >
                          ${nifCommissions.commissions.length}
                        </span>
                        <i class="far fa-eye" style="font-size:16px"></i>
                      </span>
                    </div>

                  </div>
                </div>

                <div
                  id="nif-commission-body-${nifCommissions.nif}"
                  class="card-body p-1 collapse ${allSettled ? "" : "show"}"
                  aria-labelledby="accordion-heading-${nifCommissions.nif}"
                  data-parent="#accordion-${nifCommissions.nif}">
                </div>

              </div>
            </div>
            `);

            let totalValueCommision = 0;

            nifCommissions.commissions.forEach((commission) => {
              $("#data-" + containerId + " #nif-percentaje-total-" + nifCommissions.nif).html(commission.percent_applied_all_commissions / 100 + " % de ");

              $("#accordion-heading-" + nifCommissions.nif + "> .input-group > .input-group-prepend > span:first").attr("data-toggle", "tooltip");
              $("#accordion-heading-" + nifCommissions.nif + "> .input-group > .input-group-prepend > span:first").attr("data-placement", "bottom");
              $("#accordion-heading-" + nifCommissions.nif + "> .input-group > .input-group-prepend > span:first").attr("title", commission.nifName);

              $("#data-" + containerId + " #nif-commission-body-" + nifCommissions.nif).append(`
              <div class="input-group">

                <div class="input-group-prepend">
                  <span class="input-group-text font-weight-bolder" style="min-width:130px;">
                    ${commission.percentApplied / 100}
                    % de
                    ${commission.gross_amount_source / 100} €
                  </span>
                </div>

                <input
                  type="text"
                  class="form-control font-weight-bolder text-right"
                  value="${commission.gross_amount / 100} €"
                  readonly
                >

                ${
                  commission.distribution_label
                    ? `<div class="input-group-append">
                      <span class="input-group-text bg-light text-center"
                        data-toggle="tooltip"
                        data-html="true"
                        title="
                        ${commission.distribution_label}">
                          <i class="fa-solid fa-tags" style="font-size:19px"></i>
                      </span>
                    </div>`
                    : ""
                }

                <div class="input-group-append">
                  <span class="input-group-text bg-light text-center"
                  data-toggle="tooltip"
                  data-html="true"
                  title="
                  ${"Creada <br>" + commission.created_at}">
                    <i class="far fa-calendar-alt" style="font-size:19px"></i>
                  </span>
                </div>

                <div class="input-group-append">
                  <span class="input-group-text bg-light text-center
                  ${commission.settled ? "text-success" : "text-secondary"}
                  " data-toggle="tooltip" data-html="true" title="
                  ${commission.settled ? "Liquidada <br>" + commission.settled_at : "Sin Liquidar"}">
                    <i class="fas fa-hand-holding-usd" style="font-size:19px"></i>
                  </span>
                </div>

              </div>
            `);

              $("#data-" + containerId + " #nif-gross-total-" + nifCommissions.nif).html(commission.gross_amount_source / 100 + " €");

              totalValueCommision += commission.gross_amount;
            });

            $("#data-" + containerId + " #nif-distribution-total-" + nifCommissions.nif).val(totalValueCommision / 100 + " €");
          });

          $("#data-" + containerId).append(`</div>`);

          const transacionMistmachs = Object.entries(response.data.mistmach).map(([nif, mistmach]) => ({ nif, mistmach }));
          if (transacionMistmachs.length > 0) {
            $("#data-" + containerId).append(`
          <h4 class="mt-4 mb-0 text-center">
              <strong>COMISIONES POR DESCUADRE</strong>
          </h4>
        `);
            transacionMistmachs.forEach((nifMistmachs) => {
              let allMistmachSettled = nifMistmachs.mistmach.every((commission) => commission.settled === true);

              $("#data-" + containerId).append(`
              <div
                id="mistmach-accordion-${nifMistmachs.nif}"
                class="col-12 text-center mt-2 px-0"
              >
                <div class="card">

                  <div
                    class="card-header p-0"
                    id="mistmach-accordion-heading-${nifMistmachs.nif}"
                  >
                    <div class="input-group">

                      <div class="input-group-prepend">
                        <span class="input-group-text font-weight-bolder" style="min-width:91px;">
                          ${nifMistmachs.nif}
                        </span>
                        <span class="input-group-text" style="border-radius: 0;">
                          <span
                            id="nif-percentaje-mistmach-total-${nifMistmachs.nif}"
                          ></span>

                          <span
                            id="nif-gross-mistmach-total-${nifMistmachs.nif}"
                            class="pl-1"
                          ></span>

                        </span>
                      </div>

                      <input id="nif-distribution-mistmach-total-${nifMistmachs.nif}" type="text" class="form-control font-weight-bolder text-right" value="0 €" readonly>

                      <div
                        class="input-group-append"
                        style="cursor:pointer"
                        data-toggle="collapse"
                        data-target="#nif-mistmach-body-${nifMistmachs.nif}"
                        aria-expanded="${allMistmachSettled ? "false" : "true"}"
                        aria-controls="nif-mistmach-body-${nifMistmachs.nif}"
                      >
                        <span
                        class="input-group-text bg-light text-center text-${allMistmachSettled ? "success" : "secondary"}"
                        data-toggle="tooltip"
                        data-html="true"
                        title="Cantidad de comisiones por descuadre<br>Mostrar / Ocultar comisiones">

                        <span class="badge badge-${allMistmachSettled ? "success" : "secondary"} mr-2">
                            ${nifMistmachs.mistmach.length}
                          </span>
                          <i class="far fa-eye" style="font-size:16px"></i>
                        </span>

                      </div>

                    </div>
                  </div>

                  <div
                    id="nif-mistmach-body-${nifMistmachs.nif}"
                    class="card-body p-1 collapse ${allMistmachSettled ? "" : "show"}"
                    aria-labelledby="mistmach-accordion-heading-${nifMistmachs.nif}"
                    data-parent="#mistmach-accordion-${nifMistmachs.nif}">
                  </div>

                </div>
              </div>
              `);

              let totalValueCommisionMistmach = 0;

              nifMistmachs.mistmach.forEach((commission) => {
                $("#data-" + containerId + " #nif-percentaje-mistmach-total-" + nifMistmachs.nif).html(commission.percent_applied_all_commissions / 100 + " % de ");

                $("#data-" + containerId + " #nif-mistmach-body-" + nifMistmachs.nif).append(`
                <div class="input-group">

                  <div class="input-group-prepend">
                    <span class="input-group-text font-weight-bolder" style="min-width:130px;">
                      ${commission.percentApplied / 100}
                      % de
                      ${commission.gross_amount_source / 100} €
                    </span>
                  </div>

                  <input
                    type="text"
                    class="form-control font-weight-bolder text-right"
                    value="${commission.gross_amount / 100} €"
                    readonly
                  >

                  ${
                    commission.distribution_label
                      ? `<div class="input-group-append">
                        <span class="input-group-text bg-light text-center"
                          data-toggle="tooltip"
                          data-html="true"
                          title="
                          ${commission.distribution_label}">
                            <i class="fa-solid fa-tags" style="font-size:19px"></i>
                        </span>
                      </div>`
                      : ""
                  }

                  <div class="input-group-append">
                    <span class="input-group-text bg-light text-center"
                    data-toggle="tooltip"
                    data-html="true"
                    title="
                    ${"Creada <br>" + commission.created_at}">
                      <i class="far fa-calendar-alt" style="font-size:19px"></i>
                    </span>
                  </div>

                  <div class="input-group-append">
                    <span class="input-group-text bg-light text-center
                    ${commission.settled ? "text-success" : "text-secondary"}
                    " data-toggle="tooltip" data-html="true" title="
                    ${commission.settled ? "Liquidada <br>" + commission.settled_at : "Sin Liquidar"}">
                      <i class="fas fa-hand-holding-usd" style="font-size:19px"></i>
                    </span>
                  </div>

                </div>
              `);

                $("#data-" + containerId + " #nif-gross-mistmach-total-" + nifMistmachs.nif).html(commission.gross_amount_source / 100 + " €");

                totalValueCommisionMistmach += commission.gross_amount;
              });

              $("#data-" + containerId + " #nif-distribution-mistmach-total-" + nifMistmachs.nif).val(totalValueCommisionMistmach / 100 + " €");
            });
          }
        } else {
          $("#btn-modal-make-commission").html('<i class="fas fa-money-bill-wave pr-3"></i> Comisionado Automático');
          $("#btn-make-commission").text("Comisionar");

          $("#btn-modal-make-custom-distribution").html('<i class="fa-solid fa-money-bill-transfer pr-3"></i> Comisionado Manual');

          $("#btn-make-custom-commission").text("Comisionar");
        }
      } else {
        $("#btn-modal-make-commission").html('<i class="fas fa-money-bill-wave pr-3"></i> Comisionado Automático');
        $("#btn-make-commission").text("Comisionar");

        $("#btn-modal-make-custom-distribution").html('<i class="fa-solid fa-money-bill-transfer pr-3"></i> Comisionado Manual');

        $("#btn-make-custom-commission").text("Comisionar");

        $("#data-" + containerId).html(`
        <div class="col-12 text-center">
          <p>Esta transacción aún no está comisionada, puedes utilizar el botón de comisionar.</p>
        </div>
      `);
      }

      $("#data-" + containerId).removeClass("d-none");
      $("#load-" + containerId).addClass("d-none");
      $('[data-toggle="tooltip"]').tooltip();
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

function updateTransaction() {
  $("#btn-update-transaction").attr("disabled", true);
  $("#btn-update-transaction").html(` <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>` + ` Actualizando...`);

  let transactionId = $("#transaction-id");
  let grossAmount = $("#transaction-update-gross_amount");
  let taxPercent = $("#transaction-update-tax_percentaje");
  let netAmount = $("#transaction-update-net_amount");
  let paymentId = $("#transaction-update-payment");
  let operationType = $("#transaction-update-operation");
  let transactionType = $("#transaction-update-transaction-type");

  if (paymentId.val() !== "" && !isValidUuid(paymentId.val())) {
    createToast("error", "Error al actualizar la transacción", "El ID del pago no es válido", 5000);
    $("#btn-update-transaction").attr("disabled", false);
    $("#btn-update-transaction").html(`Actualizar`);
    return;
  }

  if (transactionId.val() && grossAmount.val() && taxPercent.val() && netAmount.val() && transactionType.val() && operationType.val()) {
    var formData = new FormData();

    formData.append("transactionId", transactionId.val() ? transactionId.val() : "");
    formData.append("grossAmount", grossAmount.val() ? grossAmount.val() : "");
    formData.append("taxPercent", taxPercent.val() ? taxPercent.val() : "");
    formData.append("netAmount", netAmount.val() ? netAmount.val() : "");
    formData.append("paymentId", paymentId.val() ? paymentId.val() : "");
    formData.append("operationType", operationType.val() ? operationType.val() : "");
    formData.append("type", transactionType.val() ? transactionType.val() : "");

    $.ajax({
      url: "/herramientas/services/ajax/updateTransaction",
      type: "POST",
      data: formData,
      processData: false,
      contentType: false,
      async: true,
    })
      .done(function (data) {
        const response = JSON.parse(data);

        if (response["status"] === 1) {
          $("#modal-update-transaction").modal("hide");
          $("#total-amount-changed").addClass("d-none");
          loadTransactionData(response.data.transaction_id);
          loadTransactionDistributionData(response.data.transaction_id);

          createToast("success", "Transacción actualizada", "La transacción se ha actualizado correctamente.", 10000);

          if (response.data.canTransactionBeDistributed && response.data.reComissionNeeded) {
            makeCommissions();
            createToast("info", "Comisiones actualizadas", "Las comisiones se han actualizado correctamente ajustandolas a la nueva configuración de la transacción.", 10000);
          }

          if (!response.data.canTransactionBeDistributed && response.data.reComissionNeeded) {
            recreateCustomCommissions();
            createToast("warning", "Comisiones actualizadas", "Las comisiones se han actualizado, al no poder ser distribuidas automaticamente, puede que se hayan generado comisiones de descuadre", 10000);
          }

          getTransactionsByServiceId(response["serviceTypeId"]);
        } else {
          createToast("error", "Error al actualizar la transacción", response["error"]["message"], 10000);
        }

        $("#load-transaction-comisions").addClass("d-none");
        $("#btn-update-transaction").attr("disabled", false);
        $("#btn-update-transaction").html(`Actualizar`);
        $('[data-toggle="tooltip"]').tooltip();
        $("#update-transaction").removeClass("was-validated");
      })
      .fail(function (e) {
        if (e.status === 401) {
          createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
          setTimeout(function () {
            location.reload();
          }, 5000);
        } else {
          createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
        }
      });
  } else {
    createToast("error", "Error al actualizar la transacción", "Ha ocurrido un error, por favor, revisa los datos introducidos", 5000);
    $("#btn-update-transaction").attr("disabled", false);
    $("#btn-update-transaction").html(`Actualizar`);
  }
}

function calculateTaxPercentage(section = "") {
  let importePago = document.getElementById(section + "gross_amount");
  let tasaPorcentaje = document.getElementById(section + "tax_percentaje");
  let valorNetoPreview = document.getElementById(section + "net_amount_preview");
  let valorNeto = document.getElementById(section + "net_amount");

  tasaPorcentaje.value > 100 ? (tasaPorcentaje.value = 100) : null;
  tasaPorcentaje.value < 0 ? (tasaPorcentaje.value = 0) : null;

  if (tasaPorcentaje.value >= 0 && tasaPorcentaje.value != "") {
    let valorNetoCalculado = (parseFloat(importePago.value) * (1 + parseFloat(tasaPorcentaje.value) / 100)).toFixed(2);

    valorNetoPreview.innerHTML = valorNetoCalculado;
    valorNeto.value = valorNetoCalculado;

    if (isNaN(valorNetoCalculado)) {
      valorNetoPreview.innerHTML = "Introduce el % de impuestos y el importe en ";
      valorNeto.value = "";
    }
  } else {
    valorNetoPreview.innerHTML = "Introduce el % de impuestos y el importe en ";
    valorNeto.value = "";
  }
}

function loadUpdateTransaction() {
  $("#data-transaction-updater").addClass("d-none");
  $("#load-transaction-updater").removeClass("d-none");
  $("#total-amount-changed").addClass("d-none");

  let transactionHasCommissions = false;

  $.ajax({
    url: "/herramientas/services/ajax/readTransaction",
    type: "POST",
    data: {
      transactionId: $("#transaction-id").val(),
    },
    async: true,
  })
    .done(function (data) {
      const response = JSON.parse(data);

      if (response["status"] === 1) {
        transactionHasCommissions = response["data"]["haveCommissions"];

        $("#transaction-update-tax_percentaje").val(response["data"]["tax_percent"] / 100);

        $("#transaction-update-tax_percentaje_actual").val(response["data"]["tax_percent"] / 100);

        $("#transaction-update-gross_amount").val(response["data"]["gross_amount"] / 100);

        $("#transaction-update-gross_amount_actual").val(response["data"]["gross_amount"] / 100);

        $("#transaction-update-net_amount_preview").val(response["data"]["net_amount"] / 100);
        $("#transaction-update-net_amount").val(response["data"]["net_amount"] / 100);

        $("#btn-update-transaction").text("Actualizar");
        var operationValue = $("#transaction-update-operation option:contains(" + response["data"]["operation_type"] + ")").val();
        $("#transaction-update-operation").val(operationValue);
        $("#transaction-update-operation").selectpicker("refresh");

        var typeValue = $("#transaction-update-transaction-type option:contains(" + response["data"]["type"] + ")").val();
        $("#transaction-update-transaction-type").val(typeValue);
        $("#transaction-update-transaction-type").selectpicker("refresh");

        if (transactionHasCommissions) {
          document.getElementById("transaction-update-tax_percentaje").addEventListener("change", checkTransactionUpdateGrossAmount);
          document.getElementById("transaction-update-tax_percentaje").addEventListener("keyup", checkTransactionUpdateGrossAmount);

          document.getElementById("transaction-update-gross_amount").addEventListener("change", checkTransactionUpdateGrossAmount);
          document.getElementById("transaction-update-gross_amount").addEventListener("keyup", checkTransactionUpdateGrossAmount);

          document.getElementById("transaction-update-transaction-type").addEventListener("change", () => {
            checkTransactionUpdateTransactionType(typeValue);
          });
        }

        $("#transaction-update-payment").val(response["data"]["payment_id"]);
      }

      calculateTaxPercentage("transaction-update-");
      $("#data-transaction-updater").removeClass("d-none");
      $("#load-transaction-updater").addClass("d-none");
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

function checkTransactionUpdateGrossAmount() {
  calculateTaxPercentage("transaction-update-");

  let grossAmount = $("#transaction-update-gross_amount");
  let grossAmountActual = $("#transaction-update-gross_amount_actual");
  let taxPercent = $("#transaction-update-tax_percentaje");
  let taxPercentActual = $("#transaction-update-tax_percentaje_actual");
  let haveCommissions = $("#trasaction-have-commision");
  if (grossAmount.val() != grossAmountActual.val() || taxPercent.val() != taxPercentActual.val()) {
    $("#btn-update-transaction").text(haveCommissions.val() == 1 ? "Actualizar y comisionar" : "Actualizar");
    $("#total-amount-changed-text").html(haveCommissions.val() == 1 ? "<p>Has modificado el importe de la transacción, esto implicará un recalculo automático de las comisiones.</p> <p>Puedes ver cual será el estado de las comisiones en el siguiente botón.</p>" : "<p>Has modificado el importe de la transacción.</p> <p>Puedes ver cual será el estado de las comisiones en el siguiente botón.</p>");
    $("#total-amount-changed").removeClass("d-none");
  } else {
    $("#total-amount-changed").addClass("d-none");
    $("#btn-update-transaction").text("Actualizar");
  }
}

function checkTransactionUpdateTransactionType(currentType) {
  let haveCommissions = $("#trasaction-have-commision");

  if (currentType != $("#transaction-update-transaction-type").val()) {
    $("#btn-update-transaction").text(haveCommissions.val() == 1 ? "Actualizar y comisionar" : "Actualizar");
    $("#total-amount-changed-text").html(haveCommissions.val() == 1 ? "<p>Has modificado el tipo de la transacción, esto implicará un recalculo automático de las comisiones.</p> <p>Puedes ver cual será el estado de las comisiones en el siguiente botón.</p>" : "<p>Has modificado el tipo de la transacción.</p> <p>Puedes ver cual será el estado de las comisiones en el siguiente botón.</p>");
    $("#total-amount-changed").removeClass("d-none");
  } else {
    $("#total-amount-changed").addClass("d-none");
    $("#btn-update-transaction").text("Actualizar");
  }
}

function resetModalCreateInputs() {
  $("#gross_amount").val(0);
  $("#tax_percentaje").val(21);
  $("#net_amount").val(0);
  $("#net_amount_preview").html("0.00");
}

function loadPreviewCommissions(onlyViewData = false) {
  $("#load-make-commission-distribution").removeClass("d-none");
  $("#data-make-commission-distribution").addClass("d-none");

  $("#load-make-commission-distribution-unasigned").removeClass("d-none");
  $("#data-make-commission-distribution-unasigned").addClass("d-none");

  $("#load-make-commission-comisions").removeClass("d-none");
  $("#data-make-commission-comisions").addClass("d-none");

  $("#load-make-commission-movements").removeClass("d-none");
  $("#data-make-commission-movements").addClass("d-none");

  $("#load-make-commission-movements-unasigned").removeClass("d-none");
  $("#data-make-commission-movements-unasigned").addClass("d-none");

  $("#data-make-commission-movements").html("");
  $("#data-make-commission-movements-unasigned").html("");

  $("#modal-make-commission").modal("show");

  loadTransactionComisionsData(null, "make-commission-comisions");

  var formData = new FormData();

  formData.append("transactionId", $("#transaction-id").val());

  let btnMakeCommission = document.getElementById("btn-make-commission");
  if (onlyViewData) {
    $("#make-commission-new-gross-amount").html(" (" + $("#transaction-update-gross_amount").val() + " €)");
    btnMakeCommission.classList.add("d-none");
    btnMakeCommission.removeAttribute("onclick");
    formData.append("grossAmount", $("#transaction-update-gross_amount").val());
    formData.append("taxPercent", $("#transaction-update-tax_percentaje").val());
    formData.append("netAmount", $("#transaction-update-net_amount").val());
    formData.append("type", $("#transaction-update-transaction-type").val());
  } else {
    btnMakeCommission.classList.remove("d-none");
    btnMakeCommission.addEventListener("click", makeCommissions);
    $("#make-commission-new-gross-amount").html("");
  }

  $.ajax({
    url: "/herramientas/services/ajax/getPreCommissionsData",
    type: "POST",
    dataType: "html",
    data: formData,
    processData: false,
    contentType: false,
    async: true,
  })
    .done(function (data) {
      const response = JSON.parse(data);
      if (response["status"] === 1) {
        const results = response["data"];

        if (results.distributions.length > 0) {
          $("#data-make-commission-distribution").html("");
          $("#data-make-commission-distribution-unasigned").html("");

          results.distributions.forEach((distribution) => {
            let transactionContainer;
            distribution.isMistmatch ? (transactionContainer = $("#data-make-commission-distribution-unasigned")) : (transactionContainer = $("#data-make-commission-distribution"));

            transactionContainer.append(`
              <div class="col-12 text-center mt-3 px-0">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text bg-light"
                      style="min-width:86px;"
                      data-toggle="tooltip"
                      title="NIF del usuario"
                      >
                        ${distribution.nif}
                    </span>
                    <span
                      class="input-group-text bg-light"
                      style="border-radius: 0; min-width:50px;"
                      data-toggle="tooltip"
                      title="Porcentaje obtenido"
                      >
                      ${distribution.percentApplied / 100} %
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control font-weight-bolder"
                    value="${distribution.amount / 100} €"
                    data-toggle="tooltip"
                    title="Importe obtenido por el usuario"
                    readonly
                  >
                  <div class="input-group-append">
                  <span
                    class="input-group-text bg-light text-barymont-red text-center"
                    data-toggle="tooltip"
                    title="${distribution.label}">
                    <i class="far fa-question-circle" style="font-size:19px"></i>
                  </span>
                  </div>
                </div>
              </div>
            `);
          });
        } else {
          $("#data-make-commission-distribution").html(`
          <div class="col-12 text-center">
            <p>No hay distribuciones</p>
          </div>
        `);
        }

        if ($("#data-make-commission-distribution-unasigned").html() == "") {
          $("#data-make-commission-distribution-unasigned").html(`
          <div class="col-12 text-center">
            <p>No hay distribuciones sin asignar</p>
          </div>
        `);
        }

        $("#data-make-commission-distribution").removeClass("d-none");
        $("#load-make-commission-distribution").addClass("d-none");

        $("#data-make-commission-distribution-unasigned").removeClass("d-none");
        $("#load-make-commission-distribution-unasigned").addClass("d-none");

        if (results.preCommissions.length > 0) {
          results.preCommissions.forEach((preCommission) => {
            let preCommisionsMovementContainer = preCommission.mistmach ? "data-make-commission-movements-unasigned" : "data-make-commission-movements";
            $("#" + preCommisionsMovementContainer).append(`
          <div class="col-12 mt-3 px-0">
            <p class="mb-1">
              A <strong>${preCommission.nif}</strong> le pertenece un
              <strong>
                ${preCommission.gross_amount < 0 && preCommission.draw_info.commission_total_gross_amount > 0 ? "-" : ""}
                ${preCommission.percent / 100} %
              </strong> del total de la transacción
              <strong>
                (
                ${preCommission.draw_info.current_precommission_gross_amount_source / 100}
                € )
              </strong> son
              <strong>
                ${preCommission.gross_amount < 0 && preCommission.draw_info.commission_total_gross_amount > 0 ? "-" : ""}
                ${preCommission.draw_info.commission_total_gross_amount / 100} €
              </strong><br>

              Actualmente tiene comisionado
              <strong>
              ${preCommission.draw_info.commissioned / 100} €
              </strong> de los cuales <strong>
              ${preCommission.draw_info.settled / 100} €
              </strong> ya están liquidados de un total de <strong>
              ${preCommission.draw_info.commission_gross_amount_source != 0 ? preCommission.draw_info.commission_gross_amount_source / 100 + " €</strong> (con el valor de la anterior comisión)" : preCommission.gross_amount_source / 100 + " €</strong> (con el valor de la comisión actual)"}
              <br>

              Para ajustar el importe que le pertenece se va a generar la siguiente comisión:
            </p>
          </div>

          <div class="col-12 text-center mb-3 px-0">
            <div class="input-group mb-4">
              <div class="input-group-prepend">
                <span
                  class="input-group-text bg-light px-2 font-weight-bolder"
                  style="min-width:86px;"
                  data-toggle="tooltip"
                  title="NIF del usuario"
                  >
                    ${preCommission.nif}
                </span>
              </div>
              <input
                type="text"
                class="form-control font-weight-bolder px-2 text-right"
                value="${preCommission.gross_amount / 100} €"
                data-toggle="tooltip"
                title="Importe a comisionar"
                readonly
              >
              <div class="input-group-append">
                <span
                  class="input-group-text bg-light text-secondary text-center"
                  >
                  Total
                </span>
            </div>
          </div>
          `);
          });
        } else {
          if (results.commissions.length > 0) {
            $("#data-make-commission-movements").html(`
            <div class="col-12 text-center">
              <p>Esta transacción no ha tenido cambios que afecten a las liquidaciones, desde el último comisionamiento, por lo tanto no se generará ningún movimiento</p>
            </div>
            `);
          } else {
            $("#data-make-commission-movements").html(`
            <div class="col-12 text-center">
              <p>Esta transacción aún no está comisionada, puedes utilizar el botón de comisionar.</p>
            </div>
            `);
          }
        }

        if ($("#data-make-commission-movements-unasigned").html() == "") {
          $("#data-make-commission-movements-text").addClass("d-none");
        } else {
          $("#data-make-commission-movements-text").removeClass("d-none");
        }

        $("#load-make-commission-movements-unasigned").addClass("d-none");
        $("#data-make-commission-movements-unasigned").removeClass("d-none");

        $("#data-make-commission-movements").removeClass("d-none");
        $("#load-make-commission-movements").addClass("d-none");

        $('[data-toggle="tooltip"]').tooltip();

        $("#btn-make-commission").attr("disabled", false);
        $("#btn-make-commission").html(`Comisionar`);

        $("#load-make-commission").addClass("d-none");
        $("#data-make-commission").removeClass("d-none");
      } else {
        createToast("error", "Error al previsualizar las comisiones", response["error"]["message"], 5000);

        setTimeout(function () {
          $("#modal-make-commission").modal("hide");
        }, 500);
      }
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

function makeCommissions() {
  $("#btn-make-commission").attr("disabled", true);
  $("#btn-make-commission").html(` <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>` + ` Comisionando...`);

  $("#load-make-commission").removeClass("d-none");
  $("#data-make-commission").addClass("d-none");

  $.ajax({
    url: "/herramientas/services/ajax/saveCommissions",
    type: "POST",
    dataType: "html",
    data: {
      transactionId: $("#transaction-id").val(),
    },
    async: true,
  })
    .done(function (data) {
      const response = JSON.parse(data);
      if (response["status"] === 1) {
        loadTransactionComisionsData();
        $("#modal-make-commission").modal("hide");
      } else {
        createToast("warning", "Ha ocurrido un error", "Ha ocurrido un error al comisionar la transacción. " + response.error.message, 5000);
      }
      $("#btn-make-commission").attr("disabled", false);
      $("#btn-make-commission").html(`Comisionar`);

      $("#load-make-commission").addClass("d-none");
      $("#data-make-commission").removeClass("d-none");
      $('[data-toggle="tooltip"]').tooltip();
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

function deleteTransaction() {
  $("#modal-btn-delete-transaction").attr("disabled", true);
  $("#modal-btn-delete-transaction").html(` <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>` + ` Eliminando...`);

  $.ajax({
    url: "/herramientas/services/ajax/deleteTransaction",
    type: "POST",
    dataType: "html",
    data: {
      transactionId: $("#transaction-id").val(),
    },
    async: true,
  })
    .done(function (data) {
      const response = JSON.parse(data);
      if (response["status"] === 1) {
        $("#modal-delete-transaction").modal("hide");
        $("#modal-transaction-info").modal("hide");
        getTransactionsByServiceId(response["serviceTypeId"]);
      } else {
        createToast("warning", "Ha ocurrido un error", "Ha ocurrido un error al eliminar la transacción, porfavor revisa los datos e inténtalo de nuevo", 5000);
      }
      $("#modal-btn-delete-transaction").attr("disabled", false);
      $("#modal-btn-delete-transaction").html(`Eliminar`);
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

function addCustomCommissionNifRow(containerId, showDeleteButton = true, nif = "", amount = "", distributionLabel = "") {
  const row = document.createElement("tr");
  const nifCell = document.createElement("td");
  const amountCell = document.createElement("td");
  const distributionLabelCell = document.createElement("td");
  const toolsCell = document.createElement("td");

  row.appendChild(nifCell);
  row.appendChild(amountCell);
  row.appendChild(distributionLabelCell);
  row.appendChild(toolsCell);

  const nifInput = document.createElement("input");
  nifInput.dataset.customComissionNif = true;
  nifInput.type = "text";
  nifInput.classList.add("form-control");
  nifInput.placeholder = "NIF";
  nifInput.value = nif;

  if (nif !== "") {
    nifInput.setAttribute("readonly", true);
    nifInput.setAttribute("title", "Este NIF no puede ser modificado ya que es una comisión liquidada");
  }

  nifInput.addEventListener("input", function () {
    this.value = this.value.toUpperCase();
    this.value = this.value.replace(/[^A-Z0-9]/g, "");

    if (ValidateSpanishID(this.value).valid) {
      this.classList.remove("is-invalid");
      this.classList.add("is-valid");
    } else {
      this.classList.remove("is-valid");
      this.classList.add("is-invalid");
    }
  });

  nifCell.appendChild(nifInput);

  const amountInput = document.createElement("input");
  amountInput.dataset.customComissionAmount = true;
  amountInput.type = "number";
  amountInput.step = "0.5";
  amountInput.classList.add("form-control");
  amountInput.placeholder = "Importe";
  amountInput.value = amount;

  if (amount !== "") {
    amountInput.setAttribute("readonly", true);
    amountInput.setAttribute("title", "Este importe no puede ser modificado ya que es una comisión liquidada");
  }

  amountInput.addEventListener("input", function () {
    calculateAmountNotDistributed();
  });

  amountCell.appendChild(amountInput);

  if (nif !== "" && amount !== "") {
    const liquidatedBadge = document.createElement("span");
    liquidatedBadge.classList.add("btn");
    liquidatedBadge.classList.add("btn-block");
    liquidatedBadge.classList.add("btn-barymont-grey");
    liquidatedBadge.classList.add("text-success");
    liquidatedBadge.innerHTML = "<i class='fas fa-hand-holding-usd'></i>";
    liquidatedBadge.setAttribute("title", "Comisión liquidada");
    liquidatedBadge.setAttribute("disabled", true);
    liquidatedBadge.style.cursor = "not-allowed";
    toolsCell.appendChild(liquidatedBadge);
  }

  const distributionLabelInput = document.createElement("input");
  distributionLabelInput.dataset.customComissionDistributionLabel = true;
  distributionLabelInput.type = "text";
  distributionLabelInput.classList.add("form-control");
  distributionLabelInput.placeholder = "";
  distributionLabelInput.value = distributionLabel;

  distributionLabelCell.appendChild(distributionLabelInput);

  if (distributionLabel !== "") {
    distributionLabelInput.setAttribute("readonly", true);
    distributionLabelInput.setAttribute("title", "Este valor no puede ser modificado ya que es una comisión liquidada");
  }

  if (showDeleteButton) {
    const deleteButton = document.createElement("button");
    deleteButton.classList.add("btn");
    deleteButton.classList.add("btn-block");
    deleteButton.classList.add("btn-barymont-red");
    deleteButton.innerHTML = "<i class='fas fa-trash'></i>";
    deleteButton.addEventListener("click", function () {
      this.parentElement.parentElement.remove();
      calculateAmountNotDistributed();
    });

    toolsCell.appendChild(deleteButton);
  }

  document.querySelector(containerId).appendChild(row);
}

function calculateAmountNotDistributed() {
  const transactionGrossAmount = parseFloat(document.getElementById("transaction-gross-amount").value);

  let restAmountToDistribute = transactionGrossAmount;

  document.querySelectorAll("[data-custom-comission-amount]").forEach(function (element) {
    restAmountToDistribute -= parseFloat(element.value) || 0;
  });

  restAmountToDistribute = Math.round((restAmountToDistribute + Number.EPSILON) * 1000) / 1000;

  let restAmountToDistributeFormatted = restAmountToDistribute + " €";

  let makeCustomCommisionRestAmount = document.getElementById("make-custom-amount-not-distributed");

  makeCustomCommisionRestAmount.innerHTML = restAmountToDistributeFormatted;

  if (restAmountToDistribute !== 0) {
    makeCustomCommisionRestAmount.classList.remove("text-success");
    makeCustomCommisionRestAmount.classList.add("text-danger");
  } else {
    makeCustomCommisionRestAmount.classList.remove("text-danger");
    makeCustomCommisionRestAmount.classList.add("text-success");
  }
}

function makeCustomCommissions() {
  let nifAmounts = [];

  document.querySelectorAll("[data-custom-comission-nif]").forEach(function (element) {
    let nif = element.value;
    let amount = element.parentElement.parentElement.querySelector("[data-custom-comission-amount]").value;
    let distributionLabel = element.parentElement.parentElement.querySelector("[data-custom-comission-distribution-label]").value;

    if (nif !== "" && amount !== "") {
      nifAmounts.push({
        nif: nif,
        amount: amount,
        distributionLabel: distributionLabel,
      });
    }
  });

  if (nifAmounts.length == 0) {
    createToast("error", "Ha ocurrido un error", "Debes introducir al menos un NIF y un importe", 5000);
    return;
  }

  // Ensure all NIFs are valid
  let allNifsValid = true;

  nifAmounts.forEach(function (nifAmount) {
    let dniCheck = ValidateSpanishID(nifAmount.nif);

    if (!dniCheck.valid) {
      allNifsValid = false;
    }
  });

  if (!allNifsValid) {
    createToast("error", "Ha ocurrido un error", "Alguno de los NIFs introducidos no es válido", 5000);
    return;
  }

  // Ensure all amounts are valid
  let allAmountsValid = true;

  nifAmounts.forEach(function (nifAmount) {
    if (nifAmount.amount == "" || isNaN(nifAmount.amount)) {
      allAmountsValid = false;
    }
  });

  if (!allAmountsValid) {
    createToast("error", "Ha ocurrido un error", "Alguno de los importes introducidos no es válido", 5000);
    return;
  }

  let totalAmount = parseFloat(document.getElementById("make-custom-commission-total-amount").innerHTML.replace(" €", "").replace(",", ""));
  let totalDistributed = 0;

  nifAmounts.forEach(function (nifAmount) {
    totalDistributed += parseFloat(nifAmount.amount);
  });

  if (totalDistributed.toFixed(2) != totalAmount.toFixed(2)) {
    createToast("error", "Ha ocurrido un error", "El importe total a distribuir no coincide con la suma de los importes introducidos", 5000);
    return;
  }

  const transactionId = document.getElementById("transaction-id").value;

  let formData = new FormData();
  formData.append("transactionId", transactionId);
  formData.append("distribution", JSON.stringify(nifAmounts));

  let requestOptions = {
    method: "POST",
    body: formData,
  };

  fetch("/herramientas/services/ajax/saveCustomCommissions", requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 1) {
        createToast("success", "Comisiones generadas", "Se han generado las comisiones correctamente", 5000);
        loadTransactionComisionsData(transactionId);
        $("#modal-make-custom-commission").modal("hide");
      } else {
        createToast("error", "Ha ocurrido un error", "Ha ocurrido un error al generar las comisiones", 5000);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

function recreateCustomCommissions() {
  const transactionId = document.getElementById("transaction-id").value;

  let formData = new FormData();
  formData.append("transactionId", transactionId);

  let requestOptions = {
    method: "POST",
    body: formData,
  };

  fetch("/herramientas/services/ajax/recreateCustomCommissions", requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 1) {
        createToast("success", "Comisiones generadas", "Se han generado las comisiones correctamente", 5000);
        loadTransactionComisionsData(transactionId);
      } else {
        createToast("error", "Ha ocurrido un error", "Ha ocurrido un error al generar las comisiones", 5000);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

function generateNifInputsForCustomCommissions(containerId) {
  // Internal function to find settled commissions
  function findSettledCommissions(commissionData) {
    let settledCommissions = [];
    for (let nif in commissionData.commission) {
      commissionData.commission[nif].forEach((commission) => {
        if (commission.settled) {
          settledCommissions.push(commission);
        }
      });
    }
    return settledCommissions;
  }

  const transactionId = document.getElementById("transaction-id").value;
  let formData = new FormData();

  formData.append("transactionId", transactionId);

  let requestOptions = {
    method: "POST",
    body: formData,
  };

  fetch("/herramientas/services/ajax/getCommissionsByTransactionId", requestOptions)
    .then((response) => response.json())
    .then((jsonData) => {
      if (jsonData.status === 1) {
        const settledComissions = findSettledCommissions(jsonData.data);
        if (settledComissions.length > 0) {
          settledComissions.forEach((commission) => {
            addCustomCommissionNifRow(containerId, false, commission.nif, commission.gross_amount / 100, commission.distribution_label);
          });
        } else {
          addCustomCommissionNifRow(containerId, false);
        }
      } else {
        addCustomCommissionNifRow(containerId, false);
      }
      calculateAmountNotDistributed();
    })
    .catch((error) => {
      console.error("Error: ", error);
    });
}

async function checkPermissionByServiceType(serviceType, { financialEducationServiceAccessChecker = null, spadServiceAccessChecker = null, pymeServiceAccessChecker = null, cpServiceAccessChecker = null, financialPlanningServiceAccessChecker = null } = {}, { financialEducationServiceCheckFunction = null, spadServiceCheckFunction = null, pymeServiceCheckFunction = null, cpServiceCheckFunction = null, financialPlanningCheckFunction = null } = {}) {
  switch (serviceType) {
    case serviceTypeUUID.financialEducationService: {
      if (!financialEducationServiceAccessChecker && !financialEducationServiceCheckFunction) {
        throw new Error("You must provide an accessChecker for this service type");
      }
      if (AppGbSession.checkUserHasPermission(financialEducationServiceAccessChecker)) {
        return true;
      }
      if (typeof financialEducationServiceCheckFunction === "function") {
        return await financialEducationServiceCheckFunction();
      }
      return false;
    }

    case serviceTypeUUID.spadService: {
      if (!spadServiceAccessChecker && !spadServiceCheckFunction) {
        throw new Error("You must provide an accessChecker for this service type");
      }
      if (AppGbSession.checkUserHasPermission(spadServiceAccessChecker)) {
        return true;
      }
      if (typeof spadServiceCheckFunction === "function") {
        return await spadServiceCheckFunction();
      }
      return false;
    }

    case serviceTypeUUID.pymeService: {
      if (!pymeServiceAccessChecker && !pymeServiceCheckFunction) {
        throw new Error("You must provide an accessChecker for this service type");
      }
      if (AppGbSession.checkUserHasPermission(pymeServiceAccessChecker)) {
        return true;
      }
      if (typeof pymeServiceCheckFunction === "function") {
        return await pymeServiceCheckFunction();
      }
      return false;
    }

    case serviceTypeUUID.cpService: {
      if (!cpServiceAccessChecker && !cpServiceCheckFunction) {
        throw new Error("You must provide an accessChecker for this service type");
      }
      if (AppGbSession.checkUserHasPermission(cpServiceAccessChecker)) {
        return true;
      }
      if (typeof cpServiceCheckFunction === "function") {
        return await cpServiceCheckFunction();
      }
      return false;
    }

    case serviceTypeUUID.financialPlanningService: {
      if (!financialPlanningServiceAccessChecker && !financialPlanningCheckFunction) {
        throw new Error("You must provide an accessChecker for this service type");
      }
      if (AppGbSession.checkUserHasPermission(financialPlanningServiceAccessChecker)) {
        return true;
      }
      if (typeof financialPlanningCheckFunction === "function") {
        return await financialPlanningCheckFunction();
      }
      return false;
    }

    default:
      throw new Error("The service type provided is not valid");
  }
}

function getTransactionsByServiceIdForDatatableModal(serviceId) {
  $("#load-transactions-data-container").removeClass("d-none");
  $("#data-transactions-data-container").addClass("d-none");
  $("#transactions-data").html("");

  $.ajax({
    url: "/herramientas/services/ajax/getTransactionsByServiceId",
    type: "POST",
    data: {
      serviceId: serviceId,
    },
    async: true,
  })
    .done(function (data) {
      let response = JSON.parse(data);

      if (response["status"] === 1) {
        if (response["data"].length > 0) {
          $("#transactions-data").html("");
          response["data"].forEach((transaction) => {
            $("#transactions-data").append(`
                  <div class="col-12 text-center" style="min-width: 400px;">
                      <div class="input-group mb-3 ">
                          <div class="input-group-prepend">
                          <span class="input-group-text"data-toggle="tooltip"
                          data-placement="right"
                          title="${transaction.type}">
                          <i class="fas fa-solid ${transaction.type_icon}"></i>
                          </span>
                        </div>

                      <input type="text"
                          class="form-control font-weight-bolder ${transaction.gross_amount < 0 ? "text-danger" : ""}""
                          value="${transaction.gross_amount / 100} €"
                          style="max-width: 85px"
                          data-toggle="tooltip"
                          data-placement="left"
                          title="Importe bruto"
                          readonly
                          >

                      <input type="text"
                          class="form-control font-weight-bolder ${transaction.net_amount < 0 ? "text-danger" : ""}"
                              value="${transaction.net_amount / 100} €"
                              style="max-width: 85px"
                              data-toggle="tooltip"
                              data-placement="left"
                              title="Importe neto"
                              readonly
                          >

                      <input type="text"
                          class="form-control font-weight-bolder"
                          style="max-width: 55px"
                          value="${transaction.tax_percent / 100}%"
                          data-toggle="tooltip"
                          data-placement="left"
                          title="% de impuestos"
                          readonly
                          >

                      <input type="text"
                          class="form-control font-weight-bolder"
                          value="${transaction.created_at.slice(0, -3)}"
                          data-toggle="tooltip"
                          data-placement="left"
                          title="Fecha de creación"
                          readonly
                          >

                      </div>
                  </div>
                  `);
          });
        } else {
          $("#transactions-data").append(`
              <div class="col-12 text-center">
                  <p>No existen transacciones para este servicio</p>
              </div>
              `);
        }

        $("#load-transactions-data-container").addClass("d-none");
        $("#data-transactions-data-container").removeClass("d-none");

        $('[data-toggle="tooltip"]').tooltip();
        return true;
      } else {
        $("#transactions-data").append(`
              <div class="col-12 text-center">
                  <p>No se han podido cargar las transacciones del servicio, ponte en contacto con el administrador</p>
              </div>
          `);
        createToast("danger", "Ha ocurrido un error", "No se han podido cargar las transacciones del servicio, ponte en contacto con el administrador", 15000);
        $("#load-transactions-data-container").addClass("d-none");
        $("#data-transactions-data-container").removeClass("d-none");
        return false;
      }
    })
    .fail(function (e) {
      if (e.status === 401) {
        createToast("error", "Tu sesión ha caducado", "Tu sesión ha caducado, por favor, vuelve a iniciar sesión.<br><b>Recargaremos la página automáticamente en 5 segundos.</b>", 6000);
        setTimeout(function () {
          location.reload();
        }, 5000);
      } else {
        createToast("error", "Error", "Se ha producido un error al cargar los datos, por favor, inténtalo de nuevo.", 10000);
      }
    });
}

function addCommonServiceModalsEventListeners() {
  document.body.addEventListener("click", function (event) {
    if (event.target.getAttribute("showServiceTinyTransactionsModal") !== null) {
      $("#modal-transactions-list").modal("show");
      getTransactionsByServiceIdForDatatableModal(event.target.getAttribute("data-service-id"));
    }
  });
}

window.addEventListener("load", async function () {
  if (window.location.pathname.includes("/herramientas/servicio/")) {
    document.querySelector("#modal-create-transaction #create-transaction #gross_amount")?.addEventListener("keyup", () => calculateTaxPercentage());

    document.querySelector("#modal-create-transaction #create-transaction #tax_percentaje")?.addEventListener("keyup", () => calculateTaxPercentage());

    document.getElementById("create-transaction")?.addEventListener("submit", function (event) {
      event.preventDefault();
      createTransaction();
    });

    document.getElementById("service-delete")?.addEventListener("submit", function (event) {
      event.preventDefault();
      deleteService();
    });

    document.getElementById("btn-modify-transaction")?.addEventListener("click", loadUpdateTransaction);

    document.getElementById("transaction-update-gross_amount")?.addEventListener("keyup", checkTransactionUpdateGrossAmount);
    document.getElementById("transaction-update-tax_percentaje")?.addEventListener("keyup", checkTransactionUpdateGrossAmount);

    document.getElementById("update-transaction")?.addEventListener("submit", function (event) {
      event.preventDefault();
      updateTransaction();
    });

    document.getElementById("btn-show-comissions")?.addEventListener("click", () => loadPreviewCommissions(true));

    document.getElementById("btn-modal-make-commission")?.addEventListener("click", () => loadPreviewCommissions());

    document.getElementById("transaction-delete")?.addEventListener("submit", function (event) {
      event.preventDefault();
      deleteTransaction();
    });

    document.getElementById("btn-make-commission")?.addEventListener("click", makeCommissions);
    document.getElementById("make-commission")?.addEventListener("submit", function (event) {
      event.preventDefault();
      updateTransaction();
    });

    document.querySelector("#modal-make-custom-commission #btn-add-custom-commission-nif-row")?.addEventListener("click", () => addCustomCommissionNifRow("#custom-comission-nif-table", true));

    document.getElementById("btn-make-custom-commission")?.addEventListener("click", makeCustomCommissions);

    document.querySelector("#modal-update-service #service-update")?.addEventListener("submit", function (event) {
      event.preventDefault();
      updateService();
    });

    document.addEventListener("serviceUpdated", () => getServiceData());

    const serviceTypeId = await getServiceData();
    getTransactionsByServiceId(serviceTypeId);
    getServiceCustomers($("#service-id").val(), serviceTypeId);

    let transactionIdInput = document.getElementById("transaction-id");

    if (transactionIdInput.value !== "") {
      loadTransactionInfo(transactionIdInput.value, serviceTypeId);
    }

    $("#modal-make-custom-commission").on("show.bs.modal", function () {
      const transactionGrossAmount = document.getElementById("transaction-gross-amount").value;
      document.getElementById("make-custom-commission-total-amount").innerHTML = transactionGrossAmount;
      generateNifInputsForCustomCommissions("#custom-comission-nif-table");
    });

    $("#modal-make-custom-commission").on("hide.bs.modal", function () {
      document.getElementById("custom-comission-nif-table").innerHTML = "";
      document.getElementById("make-custom-commission-total-amount").innerHTML = "";
    });

    addCommonServiceModalsEventListeners();
    addServiceVisibilityEventListener();

    document.getElementById("btn-service-add-customer-modal")?.addEventListener("click", async () => {
      await loadAndOpenRegisterCustomerModal($("#service-id").val());
    });

    document.addEventListener("ServiceCustomerUpdated", function () {
      getServiceCustomers($("#service-id").val(), serviceTypeId);
    });

    document.getElementById("btn-service-add-expert-modal")?.addEventListener("click", async () => {
      switch (serviceTypeId) {
        case serviceTypeUUID.pymeService:
          await loadAndOpenRegisterExpertModal($("#service-id").val(), "PymeService:PymeServiceUpdateAccessChecker");
          break;
        case serviceTypeUUID.financialPlanningService:
          await loadAndOpenRegisterExpertModal($("#service-id").val(), "FinancialPlanningService:FinancialPlanningServiceManageAccessChecker");
          break;
        default:
          document.getElementById("experts-container").remove();
          break;
      }
    });
  }
});

export { addCommonServiceModalsEventListeners, checkPermissionByServiceType };
