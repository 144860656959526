import handleApiResponse from "../../core/application/handleApiResponse";

interface params {
  serviceId: string;
  serviceFileId: string;
}

interface DeleteServiceFileParams {
  message: string;
}

export default async function deleteServiceFile(params: params): Promise<DeleteServiceFileParams> {
  const response = await fetch(`/api/service/${params.serviceId}/file/${params.serviceFileId}`, {
    method: "DELETE",
  });
  return handleApiResponse<DeleteServiceFileParams>(response);
}
