import ApiRequestError from "../../../../api/core/domain/apiRequestError";
import uploader from "../../../../api/services/application/uploadServiceFile";

interface UploadServiceFileParams {
  serviceId: string;
}

interface UploadServiceFilePayload {
  friendlyName: string;
  serviceFileCategoryId: string;
  file: File;
}

interface UploadServiceFileReponse {
  correctlyUploaded: boolean;
  message: string;
}

const MaxAllowedUploadSizeInMB = 60;

export default async function uploadServiceFile(params: UploadServiceFileParams, payload: UploadServiceFilePayload): Promise<UploadServiceFileReponse> {
  try {
    if (!validateFileSize(payload.file.size)) {
      return {
        correctlyUploaded: false,
        message: "El archivo supera el tamaño máximo permitido de " + MaxAllowedUploadSizeInMB + " MB.",
      };
    }

    const response = await uploader({ serviceId: params.serviceId }, { friendlyName: payload.friendlyName, serviceFileCategoryId: payload.serviceFileCategoryId, file: payload.file });

    return {
      correctlyUploaded: true,
      message: response.message,
    };
  } catch (error) {
    if (error instanceof ApiRequestError && error.status === 404) {
      return {
        correctlyUploaded: false,
        message: "El servicio no existe o no se ha encontrado.",
      };
    }

    console.error(`No se ha subido el archivo en el servicio con ID: ${params.serviceId}`);

    return {
      correctlyUploaded: false,
      message: "Ha ocurrido un error al subir el archivo. Por favor, inténtelo de nuevo más tarde.",
    };
  }
}

function validateFileSize(fileSize: number): boolean {
  const maxSize = MaxAllowedUploadSizeInMB * 1024 * 1024;

  if (fileSize > maxSize) {
    return false;
  }

  return true;
}
