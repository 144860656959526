import ApiRequestError from "../../../../api/core/domain/apiRequestError";
import deleteServiceFile from "../../../../api/services/application/deleteServiceFile";

  export default async function removeServiceFileById({serviceId, serviceFileId} : {serviceId: string, serviceFileId: string}): Promise<string | null> {

  let responseMessage: string | null;
  try {
    const response = await deleteServiceFile({ serviceId, serviceFileId });
    responseMessage = response.message;

  } catch (error) {
    if (error instanceof ApiRequestError && error.status === 404) return null;

    console.error(`No se ha encontrado el archivo con ID ${serviceId} de servicio con ID: ${serviceFileId}`);
    return null;
  }

  return responseMessage;
}
