import ApiRequestError from "../../../../api/core/domain/apiRequestError";
import searchServiceListFile from "../../../../api/services/application/searchServiceListFile";
import ServiceFile from "../../../../api/services/domain/serviceFile";

export default async function searchServiceFileCollectionByServiceId(serviceId: string): Promise<ServiceFile[]> {
  try {
    return await searchServiceListFile({ serviceId });
  } catch (error) {
    if (error instanceof ApiRequestError && error.status === 404) return [];

    console.error("No se encontraron archivos de servicio para el servicio con ID:", serviceId);
    return [];
  }
}
