import handleApiResponse from "../../core/application/handleApiResponse";
import { ServiceFileCategory } from "../domain/serviceFileCategory";

interface SearchServiceFileCategoriesParams {
  serviceTypeId: string;
}

export default async function searchServiceFileCategoriesByServiceType(params: SearchServiceFileCategoriesParams): Promise<ServiceFileCategory[]> {
  const url = `/api/service-type/${params.serviceTypeId}/file/categories`;
  const response = await fetch(url, { method: "GET" });

  return handleApiResponse<ServiceFileCategory[]>(response);
}
